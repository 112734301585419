.img-office{
    width: 21vw;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: auto!important;
    background: #ECCA20;
    border-radius: 1rem 1rem 0 0;
}
.MuiButtonBase-root{
    height: 4rem!important;
    border-radius: 1rem 1rem 0 0!important;

}
.MuiPaper-root{
    background: transparent!important;
}

.MuiCollapse-container{
    .owl-nav{
        display: flex!important;
        div{
            display: flex !important;
            width: 10vw;
            height: 10vw;
            background: rgba(255, 255, 255, 0.711);
            z-index: 10;
            border-radius: 100%;
            position: absolute;
            font-size: 0;
            border: 0;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.219) !important;
            svg{
                width: 2vw;
                height: 3vw;
                polyline{
                    stroke: rgba(0, 0, 0, 0.3)!important;
                }
            }
        }
        .owl-prev{
            svg{
                margin-right: 1vw;
            }
        }
        .owl-next{
            svg{
                margin-left: 1vw;
            }
        }
    }
    .owl-dots{
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .owl-dot{
            width: .5rem;
            height: .5rem;
            background: #6e9a21;
            border-radius: 100%;
            margin-left: .2rem;
            margin-right: .2rem;
        }
        .owl-dot.active{
            background: white;
        }
    }
}

.MuiPaper-root, .MuiPaper-root *{
    outline: 0!important;
    border: 0!important;
    box-shadow: none!important;
}
.background-color{
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

}
.lupa{
    width: 5rem;
    height: 5rem;
    background: rgba(255, 255, 255, 0.911);
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.219)!important;
    z-index: 1;
    cursor: pointer;
    transition: .5s;
    @media(max-width:740px){
        width: 15vw;
        height: 15vw;
    }
    .icon-lupa{
        width: 40%;
        height: 40%;
        background: rgba(0, 0, 0, 0.514);
    }
}

.lupa:hover{
    transform: scale(1.04);
}
.MuiTypography-body1{
    font-family: "GalanoGrotesque-Medium"!important;
    color: var(--Primary);
    font-size: 4.5vw;
    display: flex;
    align-items: center;
    .diap{
        width: 92vw;
        height: auto;
        z-index: 1;
        margin: auto;
        margin-bottom: 0vw;
        .content-img{
            width: 100%;
            height: 56vw;
            border-radius: 1.2rem!important;
            overflow: hidden;
            position: relative;

            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .play{
            width: 20vw!important;
            height: 20vw!important;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
        }
        .detail{
            .title{
                margin-top: 4vw;
                margin-bottom: 4vw;
                color: var(--secondary);
                font-family: "Raleway-Medium";
                text-align: center;
                font-size: 5.5vw;
            }
            .paragraph{
                text-align: center;
                font-family: "Raleway-Medium";
                color: var(--primary);
                font-size: 4vw;
                width: 60vw;
                position: relative;
                margin: auto;
            }
        }
    }
    #carousel-tipologia{
        .content-img{
            background: white;
            box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.295)!important;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

.MuiAccordionSummary-root {
    padding: 0px .7rem!important;
    transition: inherit!important;
}
.img-office.active{
    display: none;
}
.MuiAccordionDetails-root{
    position: relative;
    padding: 0!important;
    .MuiTypography-root{
        width: 100%!important;
    }
}
.accordeon-screen{
    width: 100%;
}
.MuiAccordion-root.Mui-expanded{
    margin-top: 0!important;
    margin-bottom: 0!important;
    min-height: auto!important;
    background: var(--secondary);
    border-radius: 5vw 5vw 0 0;
    .background-color{
        width: 100%;
        position: absolute;
        height: 48vw;
        top: 0;
        left: 0;
        background: #ECCA20;
    }
    .img-office{
        display: none;
    }
    .img-office.active{
        display: flex;
    }
    .MuiTypography-body1{
        color: white;
        
        .content-icon{
            border-radius: 100%;
            background: #ca913c;
            .icon-acordeon{
                background: white;
            }
        }
    }
    .MuiCollapse-container{
        .MuiTypography-body1{
            margin-bottom: 10vw;
        }
    }
}
.content-icon.acord{
    width: 9.5vw;
    height: 9.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5vw;
    top: 0;
    bottom: 0;
    margin: auto;
}
.icon-acordeon{
    padding: 1vw;
    width:50%;
    height: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
    background: var(--Primary);
    transition: .3s;
}
.MuiAccordion-root:before{
    display: none;
}
.MuiAccordion-rounded {
    border-radius: 6vw 6vw 0 0!important;
    border: 0!important;
    box-shadow: inherit!important;
}
.down{
    mask-image: url("../../Assets/images/iconos/down-icon.svg");
}
.Mui-expanded{
    .down{
        transform: rotate(180deg);
        transform-origin: center;
    }
}