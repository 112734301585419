.icon-whatsapp{
    mask-image: url("../../Assets/images/iconos/whatsapp.svg");
}
.icon-close{
    mask-image: url("../../Assets/images/iconos/btn-close.svg");
}
.user{
    mask-image: url("../../Assets/images/iconos/user.svg");
}
.mail{
    mask-image: url("../../Assets/images/iconos/icon-mail.svg");
}
.phone{
    mask-image: url("../../Assets/images/iconos/icon-phone.svg");
}
.phone-link{
    mask-image: url("../../Assets/images/iconos/phone.svg");
}
.content-whatsapp.unity{
    --base:#ff7f2f;
    --sky:#ff7f2f;
    --orange:#ff7f2f;
    .content-form{
        --base:#003056
    }
}

.content-whatsapp.unbox{
    --base:#292929;
    --sky:#eab280;
    --orange:#eab280;
    .content-form{
        --base:#292929
    }
}

.content-whatsapp.town{
    --base:#233442;
    --sky:#f1605f;
    --orange:#f1605f;
    .content-form{
        --base:#233442
    }
}

.content-whatsapp.nou{
    --base:#4a4a49;
    --sky:#f9b450;
    --orange:#f9b450;
    .content-form{
        --base:#4a4a49
    }
}

.content-whatsapp.trend{
    --base:#362e2c;
    --sky:white;
    --orange:#973921;
    .content-form{
        --base:#362e2c
    }
    .content-icon-toggle.active{
        --sky:#973921;
    }
}
@keyframes moveUp{
    2% {
        transform: translate3d(0,-1px,0);
    }
    6% {
        transform: translate3d(0,2px,0);
    }
    10% {
        transform: translate3d(0,-4px,0);
    }
    15% {
        transform: translate3d(0,4px,0);
    }
    20% {
        transform: translate3d(0,-4px,0);
    }
    26% {
        transform: translate3d(0,4px,0);
    }
}
#term,#term-whatsapp,#term-cuota{
    position: absolute;
    visibility: hidden;
}
.button-term{
    height: .9rem;
    width: .9rem;
    border: 1px solid var(--Primary);
    border-radius: .3rem;
    margin-right: .6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:500px){
        width: 5vw;
        height: 5vw;
    }
    cursor: pointer;
    .checked{
        width: 80%;
        height: 80%;
    }
}
#term:checked ~ .button-term,#term-whatsapp:checked  ~ .button-term,#term-cuota:checked ~ .button-term{
    background: var(--Primary);
    .checked{
        mask-image: url("../../Assets/images/iconos/checked.svg");
        mask-size: 80% 80%;
        mask-position: center;
        mask-repeat: no-repeat;
        background: white;
    }
}

#term-cuota.invalid ~ label {
    border-color: red;
}
.text-term{
    font-size: .8rem;
    color: var(--dark);
    display: block;
    padding-bottom: .3rem;
    font-family: "GalanoGrotesque-Medium";
    @media(max-width:540px){
        padding-top: .2rem;
        padding-bottom: 0rem;
        line-height: 2rem;
    }
    a{
        color: var(--dark);
        font-family: "GalanoGrotesque-Medium";
    }

    &.is-invalid {
        color: red;
        a {
            color: red;
        }
    }
}
.checkbox{
    width: .9rem;
    height: .9rem;
    margin-right: .5rem;
}
.content-whatsapp{
    .content-img-message{
        position: absolute;
        top: -8rem;
        right: 0rem;
        height: 8rem;
        @media(max-width:740px){
            right: 0;
        }
    }
    .content-img-message.none{
        display: none;
    }
    .img-message{
        filter: drop-shadow(.1rem .3rem .3rem rgba(0,0,0,.1));
    }
    .content-icon-toggle{
        animation: 4s moveUp infinite;
        .img-whatsapp{
            position: absolute;
        }
    }
    .content-icon-toggle.active{
        animation: inherit;
        .content-img-message{
            display: none;
            transition: 1s;
            opacity: 0;
        }
        .img-message{
            position: absolute;
        }
        @keyframes showMessage{
            0%{transform: scale(0);}
            100%{transform: scale(1)}
        }   
    }
}
.content-whatsapp.message{
    .content-icon-toggle{
        display: none!important;
    }
}
.modal{
    background: rgba(0, 0, 0, 0.404);
    border-radius: 0!important;
    width: 100%!important;
    height: 100%!important;
    position: fixed!important;
    left: 0;
    top: 0;
    z-index: 20!important;
    display: none!important;
    .content-form.active.message{
        position: relative;
        right: 0!important;
    }
}
.modal.close{
    animation: 1s closeModal forwards;
    -webkit-animation: 1s closeModal forwards;
}
.modal.active{
    display: flex!important;
}
@keyframes closeModal {
    0%{
        opacity: 1;
        z-index: 20!important;
    }
    99%{
        opacity: 0;
        
    }
    100%{
        opacity: 0;
        z-index: -1!important;
        display: none;
        visibility: hidden;
    }
}

.content-whatsapp{
    width: 3.9rem;  
    height: 3.9rem;
    position: fixed;
    bottom: 3vw;
    right: 3vw;
    z-index: 10;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media(min-width:1200px){
        bottom: 1vw;
    }
    @media(min-width:1400px){
        width: 4.8rem;  
        height: 4.8rem;
    }
    // box-shadow: 0px 0px 5px -2px white;
    // border: 1px solid rgba(255, 255, 255, 0.185);
    --base: #240085;
    --sky: #1ec0ca;
    --orange: #E66728;
    *{
        box-sizing: border-box;
        font-family: "GalanoGrotesque-Medium";
    }
    .content-icon-toggle{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        .content-img-message{
            top: -6.5rem;
            transform: scale(0);
            transform-origin: 100% 100%;
            animation: .5s showMessage forwards 4s;
            -webkit-animation: .5s showMessage forwards 4s;
            @media (max-width:540px){
                top: -5rem;
            }
            @keyframes showMessage{
                0%{transform: scale(0);}
                100%{transform: scale(1)}
            }
        }
        img{
            pointer-events: none;
            height: 7rem;
            @media (max-width:540px){
                pointer-events: inherit!important;
                height: 5.5rem;
            }
        }
        .close{
            position: absolute;
            top: 1%;
            right: 0%;
            width: 1.5rem;
            height: 1.5rem;
            background: var(--greenLigth);
            z-index: 2;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width:540px){
                top: -2%;
                right: -4%;
                width: 1.8rem;
                height: 1.8rem;
            }
            .icon-close{
                width: 100%;
                height: 100%;
                mask-repeat: no-repeat;
                background: white;
                mask-size: 40%;
                mask-position: center;
            }
        }
        .img-whatsapp{
            width: 100%;
            height: 100%;
        }
        .static-content{
            transform: scale(0);
            -webkit-transform: scale(0);
            width: 4.3rem;
            min-width: 4.3rem;
            height: 4.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .img-close{
                width: 100%;
                height: 100%;
            }
        }
    }
    .content-icon-toggle.active{
        .img-whatsapp{
            animation: .3s whatsapp forwards;
            -webkit-animation: .3s whatsapp forwards;
        }
        .static-content{
            border-radius: 100%;
            box-shadow: 1px 2px 13px -3px #0000086b;
            background: #80B226;
            animation: .3s iconClose forwards .3s;
            -webkit-animation: .3s iconClose forwards .3s;
           
        }
    }
    .content-icon-toggle.inactive{
        .img-whatsapp{
            
            animation: .4s whatsappExpand forwards;
        }
        .static-content{
            border-radius: 100%;
            box-shadow: 1px 2px 13px -3px #0000086b;
            background: #80B226;
            position: absolute;
            animation: .4s iconOpen forwards ;
        }
        
    }

    @keyframes iconOpen{
        0%{
            transform: scale(1);
        }
        100%{
            transform: scale(0);
        }
    }
    @-webkit-keyframes iconOpen{
        0%{
            transform: scale(1);
        }
        100%{
            transform: scale(0);
        }
    }
    
    @keyframes whatsappExpand{
        0%{
            transform: scale(0);
            position: relative;
        }
        100%{
            transform: scale(1);
        }
    }
   
    @keyframes iconClose{
        0%{
            transform: scale(0);
            -webkit-transform: scale(0);
        }
        100%{
            transform: scale(1);
            -webkit-transform: scale(1);
        }
    }
    @-webkit-keyframes iconClose{
        0%{
            transform: scale(0);
            -webkit-transform: scale(0);
        }
        100%{
            transform: scale(1);
            -webkit-transform: scale(1);
        }
    }
    @keyframes whatsapp{
        0%{
            transform: scale(1);
        }
        100%{
            transform: scale(0);
            position: absolute;
        }
    }
    @-webkit-keyframes whatsapp{
        0%{
            transform: scale(1);
        }
        100%{
            transform: scale(0);
            position: absolute;
        }
    }
    .content-icon{
        width: 55%;
        height: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            pointer-events: none;
        }
        .icon{
            width: 100%;
            height: 100%;
            background: white;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            transition: 0s;
            pointer-events: none;
        }
        .icon-close{
            width: 60%!important;
            height: 60%!important;
        }
    }
    .content-form.message{
        background: url("../../Assets/images/inicio/background-form-message.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        @media(max-width: 500px){
            height: 22.5rem!important;
            width: 86vw;
        }
    }
    .content-form{
        width: 23rem;
        background: white;
        position: absolute;
        bottom: 0rem;
        right: 6rem;
        border-radius: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.185);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.158);
        padding: 1.8rem 1.3rem;
        transform: scale(0);
        transition: .3s;
        transform-origin: 100% 100% 0;
        background: url("../../Assets/images/inicio/background-form.jpg");
        background-position: center;
        background-size: contain;
        @media(max-width: 600px){
            height: 33.5rem!important;
        }
        @media(max-width: 500px){
            height: 27.5rem!important;
        }
        .title{
            line-height: initial;
            font-size: 1.2rem;
            text-align: center;
            color: var(--third);
            margin-bottom: .3rem;
            font-family: "GalanoGrotesque-Bold";
        }
        .paragraph{
            color: var(--dark);
            font-size: .9rem;
            text-align: center;
            line-height: 1rem;
            margin-bottom: .5rem;
            font-family: "GalanoGrotesque-Bold";
        }
        .paragraph-2{
            margin-bottom: 1.5rem;
        }
        .icon,.icon-popup{
            mask-position: center;
            mask-size: 100% 96%;
            mask-repeat: no-repeat;
            bottom: .2rem;
            top: 0;
            margin: auto;
        }
        .icon{
            left: 1rem;
            width: 1rem;
            height: 1rem;
            position: absolute;
            background: var(--Primary);
        }
        .icon-popup{
            width: 1.8rem;
            height: 1.8rem;
            position: relative;
            background: white;
            margin: 0 .5rem 0 0;
        }
        .phone-link{
            width: 1.2rem;
        }
        #term{
            @media(min-width:740px){
                margin-bottom: .2rem;
                margin-right: .5rem;
            }
        }
        .content-input{
            position: relative;
            width: 100%;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .input{
                width: 100%;
                background: transparent;
                border: 0px;
                padding: .5rem .5rem .5rem 2.5rem;
                font-size: .9rem;
                color: var(--dark);    
                border: 2px solid var(--Primary);
                border-radius: 50rem;
                padding-top: .3rem;
                padding-bottom: .5rem;
                background: white;
                @media(max-width:740px){
                    padding: .35rem .2rem .35rem 2.5rem;
                }
                &.is-invalid {
                    color: red;
                    border: 2px solid red !important;

                    &::placeholder {
                        color: red;
                    }
                }
            }
            .input.invalid{
                border: 2px solid red;
            }
            .input.invalid ~ .icon{
                background: red;
            }
            .input::placeholder{
                font-family: "GalanoGrotesque-Medium";
                font-family: "GalanoGrotesque-Light";
                color: #B2B2B2;
            }
            .contactar{
                width: 100%;
                border-radius: 1rem;
                border: 0;
                background: #35a9df;
                padding: .5rem 0;
                font-size: 1rem;
                font-weight: 500;
                color: white;
                cursor: pointer;
                transition: .5s;
                padding-bottom: 0.6rem;
                padding-top: .4rem;
            }
            .contactar:hover{
                transform: scale(1.03);
            }
            
           
        }
        .terms{
            // margin-top: .5rem;
            margin-bottom: 1rem;
            @media(max-width:740px){
                margin-bottom: .5rem;
                margin-top: -.5rem;
            }
        }
        .formulario{
            display: flex;
            flex-direction: column;
        }
        .message-whatsapp{
            display: none;
        }
    }
    .content-form.active{
        transform: scale(1);
    }
    .content-form.message{
        display: flex;
        align-items: center;
        justify-content: center;
        .formulario{
            display: none!important;
        }
        .message-whatsapp{
            display: flex!important;
            flex-direction: column!important;
            align-items: center;
            justify-content: center;
            .close{
                width: 3.5rem;
                height: 3.5rem;
                position: absolute;
                top: -2rem;
                left: 0;
                right: 0%;
                margin: auto;
                background: var(--third);
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon-close{
                    width: 40%;
                    height: 40%;
                    background: white;
                }
            }
            .content-icon-message{
                width: 11.5rem;
                height: 3rem;
                position: relative;
                border-radius: .8rem;
                margin-top: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                .title-1,.title-2{
                    font-size: 1.2rem;
                    color: white;
                    padding-bottom: .4rem;
                }
                .icon{
                    width: 1.7rem;
                    height: 1.7rem;
                    background: white;
                    left: 0;
                    right: 0;
                    margin: 0 0.8rem 0 0;
                }
            }
            .title,.title-2{
                text-align: center;
                line-height: 1.5rem;
                font-weight: 500;
            }
            .title{
                font-size: 1.3rem;
                color: var(--dark);
                transform: inherit!important;
                opacity: 1!important;
                margin-bottom: 0;
            }
            .title-2{
                color: #35a9df;
                font-size: 2.2rem;
                font-family: "GalanoGrotesque-bold";
            }
            .title-3{
                font-size: 1.3rem;
                color: var(--third);
                font-family: "GalanoGrotesque-bold";
                @media(max-width:740px){
                    font-size: 1.1rem;
                    // margin-top: 2rem;   
                }
            }
            .title-4{
                font-size: 1rem;    
                font-family: "GalanoGrotesque-SemiBold";
                margin-bottom: 1rem;
                @media(max-width:740px){
                    font-size: .8rem;
                }
            }
        }
    }
}

@media(max-width:740px){
    .screen-movil{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        z-index: 1;
        visibility: hidden;
    }
    .screen-movil.active{
        visibility: visible;
     }
    .content-icon-toggle{
        z-index: 2;
    }
    .content-whatsapp{
        z-index: 14;
        @media(max-width:740px){
            bottom: 6vw;
        }
        .content-form{
            margin: auto;
            height: 24rem;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0 !important;
            @media(max-width:740px){
                height: 124vw;

            }
            .title{
                font-size: 1.1rem;
            }
            .paragraph{
                font-size: .9rem;
                margin-bottom: 2vw;
            }
            .paragraph-2{
                margin-bottom: 1rem;
            }
        }
        .content-form.active{
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0 !important;
            top: 7vw;
        }
    }
}