.politicasdeprivacidad{
    padding-top: calc(7rem + 3vw);
    padding-bottom: 4rem;
    @media(max-width:500px){
        padding-top: calc(6rem + 3vw);
        padding-bottom: 4rem;
    }
    * {
        font-family: "GalanoGrotesque-Medium";
    }
    a{
        text-decoration: underline;
    }
    .title{
        font-size: 2rem;
        margin-bottom: 4rem;
        text-align: center;
        @media(max-width:500px){
            font-size: 1.4rem;
            margin-bottom: 2rem;
        }
    }
    .container{
        margin: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        @media(min-width:800px){
            max-width: 800px;
        }
        @media(min-width:1000px){
            max-width: 1000px;
        }
        @media(max-width:500px){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    .paragraph{
        margin-bottom: 1rem;
        font-size: 1rem;
        @media(max-width:1400px){
            font-size: .9rem;
        }
        
    }
    li{
        position: relative;
        padding-left: 20px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin-bottom: 10px;
    }
    li::before{
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        width: 5px;
        height: 5px;
        background: #000;
        border-radius: 50%;
        margin: auto;
    }
    .d-flex{
        display: flex;
    }
    .content-list{
        align-items: flex-start;
    }
    .text-list{
        margin-top: 4rem;
    }
    .list{
        display: flex;
        flex-direction: column;
        border: 1px solid black;
        border-bottom: 0;
        .header{
            background-color: var(--third);
            .item{
                color: white;
            }
        }
        .d-flex{
            .item{
                padding: .5rem 1rem;
                width: 14rem;
                border-bottom: 1px solid black;
                font-size: 1rem;
                @media(max-width:1400px){
                    font-size: .9rem;
                }
            }
            .item:first-child{
                border-right: 1px solid black
            }
            .item:nth-child(2){
                width: auto;
            }
        }
    }
}