.Popup{
    position: fixed;
    z-index: 101;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.664);
    .img-popup{
        width: 34vw;
        height: auto;
    }
    .content-popup{
        position: relative;
    }
    .close{
        border-radius: 100%;
        background: var(--third);;
        width: 2.6rem;
        height: 2.6rem;
        position: absolute;
        top: -1.3rem;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        svg{
            width: 40%;
            height: 40%;
        }
        path{
            fill: white;
        }
        img{
            height: 50%;
            width: 50%;
        }
    }
}
.Popup.opacity{
    display: none;
}
@media(max-width:740px),(orientation:portrait){
    .Popup{
        width: 100%;
        .img-popup{
            width: 80vw;
            position: relative;
        }
        .close{

        }
    }
}