@font-face {
    font-family: "GalanoGrotesque-Light";
    font-display: swap;
    src: url("../fonts/GalanoGrotesque-Light.woff") format("woff");
}

@font-face {
    font-family: "GalanoGrotesque-Medium";
    font-display: swap;
    src: url("../fonts/GalanoGrotesque-Medium.woff") format("woff");
}

@font-face {
    font-family: "GalanoGrotesque-MediumItalic";
    font-display: swap;
    src: url("../fonts/GalanoGrotesque-MediumItalic.woff") format("woff");
}

@font-face {
    font-family: "GalanoGrotesque-SemiBold";
    font-display: swap;
    src: url("../fonts/GalanoGrotesque-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "GalanoGrotesque-SemiBoldItalic";
    font-display: swap;
    src: url("../fonts/GalanoGrotesque-SemiBoldItalic.woff") format("woff");
}

@font-face {
    font-family: "GalanoGrotesque-Bold";
    font-display: swap;
    src: url("../fonts/GalanoGrotesque-Bold.woff") format("woff");
}


.bold{
    font-family: "GalanoGrotesque-Bold";
    letter-spacing: 1px;
}
.semibold{
    font-family: "GalanoGrotesque-SemiBold";
}
.medium{
    font-family: "GalanoGrotesque-Medium";
}
.light{
    font-family: "GalanoGrotesque-Light";
}