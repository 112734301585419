.container{
    padding: 0 1rem;
    @media(min-width:720px){
        max-width: 720px;
    }
    @media(min-width:960px){
        max-width: 960px;
    }
    @media(min-width:1200px){
        max-width: 1200px;
    }
    @media(min-width:1600px){
        max-width: 1600px;
    }
}
.btn-youtube{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto;
    width: 5.5rem;
    height: 4rem;
    cursor: pointer;
    transition: .5s;
    &:hover{
        transform: scale(1.1);
    }
    img{
        width: 100%;
        height: 100%;
    }
}
.icon-lupa{
    mask-image: url("../../Assets/images/iconos/lupa.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
}
.mx-auto{
    margin: 0 auto;
}
.point-1{
    position: absolute;
    width: 70vw;
    right: 9vw;
    top: 10vw;
}
.b-1,.b-2,.b-3{
    position: absolute;
    z-index: -1;
}
.b-1{
    width: 32vw;
    left: 0;
    top: 0;
    z-index: -1;
    user-select: none;
}
.b-2{
    width: 21vw;
    left: 0;
    top: 45vw;
    @media(max-width:740px){
        width: 60vw;
        left: 0;
        top: 18vw;
    }
    @media(max-width:500px){
        top: 51vw;
    }
}
.b-3{
    width: 27vw;
    right: 0;
    top: 32vw;
    @media(max-width:740px){
        width: 61vw;
        top: -46vw;
    }
    @media(max-width:500px){
        top: 5vw;
    }
}
.b-bottom{
    width: 100%;
}
.background-3{
    position: absolute;
    right: 0;
    height: 30rem;
    bottom: 0rem;
    z-index: -1;
    @media (max-width:740px){
        height: 16rem;
        bottom: -2rem;
    }
}
.background-4{
    position: absolute;
    right: 0;
    height: auto;
    width: 100%;
    bottom: 0rem;
    z-index: -1;
    height: 30vw;
    object-fit: contain;
    object-position: bottom;
    @media(min-width:1000px){
        transform: translateY(0vw);
    }
    @media(min-width:1400px){
        transform: translateY(7vw);
    }
    
    @media(max-width:740px){
        object-fit: cover;
        height: 52vw;
    }
}
.b-alcancia{
    bottom: 0;
    top: 4vw;
    margin: auto;
    left: 8vw;
    width: 21vw;
    position: absolute;
    @media(max-width:1500px){
        width: 23vw;
    }
    @media(max-width:1100px){
        width: 20rem;
        left: 0;
        top: auto;
        bottom:2rem;
    }
    @media(max-width:500px){
        width: 49vw;
    }
}
.b-calculadora{
    position: absolute;
    width: 24vw;
    right: 9vw;
    bottom: 0;
    top: 9vw;
    margin: auto;
    @media(max-width:1500px){
        width: 26vw;
    }
    @media(max-width:1100px){
        width: 20rem;
        left: auto;
        top: auto;
        bottom: 0;
    }
    @media(max-width:500px){
        width: 49vw;
    }
}
.Inicio{
    padding-top: 6rem;
    position: relative;
    padding-bottom: 0;
    @media(max-width:700px){
        padding-top: 4rem;
        overflow: hidden;
        padding-bottom: 0;
    }
    @media(max-width:740px){
        .screen-movil.active {
            display:none
        }
    }
    .title{
        font-size: 2rem;
        color: var(--green2);
        @media(max-width:500px){
            font-size: 1.4rem;
        }
    }
    #player{
        transform: scale(1.07) translateY(-0px);
        transform-origin: bottom;
        @media(max-width:740px){
            transform-origin: center;
        }
    }
    .content-img-error{
        position: relative;
        width: 36vw;
        @media(max-width:940px){
            width: 62vw;
        }
        @media(max-width:640px){
            width: 93vw;
         }
        img{
            width: 100%;
        }
        .button-close{
            position: absolute;
            bottom: 6vw;
            left: 9vw;
            width: 9vw;
            height:2.3vw;
            background: var(--third);
            border-radius: 40rem;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "GalanoGrotesque-Medium";
            font-size: 1.3vw;
            padding-bottom: .8vw;
            @media(max-width:640px){
                width: 28vw;
                bottom: 13vw;
                left: 20vw;
                height: 8vw;
                padding-bottom: .5vw;
                font-size: 3.3vw;
            }
            @media(max-width:940px){
                width: 14vw;
                bottom: 10vw;
                left: 16vw;
                height: 5vw;
                padding-bottom: .5vw;
                font-size: 2.2vw;
            }
            
        }
    }
    .close{
        width: 3.5rem;
        height: 3.5rem;
        position: absolute;
        top: -2rem;
        left: 0;
        right: 0%;
        margin: auto;
        background: var(--third);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-close{
            width: 40%;
            height: 40%;
            background: white;
        }
    }
    .content-brochure{
        display: flex;
        font-size: 2rem;
        background: #35a9df;
        height: 4.5rem;
        align-items: center;
        width: 22rem;
        border-radius: 1rem;
        margin: auto;
        box-shadow: 8px 0px 10px 0px #0000001c;
        margin-top: 2rem;
        margin-bottom: 4rem;
        @media(max-width:740px){
            width: 20rem;
            height: 4rem;
        }
        @media(max-width:740px){
            width: 86%;
        }
        .icon-brochure{
            background: white;
            height: 6rem;
            width: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1rem;
            overflow: hidden;
            margin-right: 1rem;
            box-shadow: -3px 0px 10px 0px #0000001c;
            @media(max-width:740px){
                height:5rem;
                width: 5rem;
            }
            @media(max-width:500px){
                width: 22vw;
                height: 22vw;

            }
            img{
                width: 70%;
                height: 70%;
                object-fit: contain;
            }
        }
        .title{
            font-family: "GalanoGrotesque-Medium";
            font-size: 1.3rem;
            color: white;
            @media(max-width:740px){
                font-size: 1.3rem;
                flex-wrap: nowrap;
            }
            @media(max-width:500px){
                font-size: 5vw;
                margin-bottom: 0;
            }
        }
    }
    .content-inicio{
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        padding-bottom: 4rem;
        align-items: center;
        justify-content: center;
        @media(max-width:700px){
            padding-bottom: 0;
            margin-bottom: 2rem;
        }
        @media(min-width:1400px){
            padding-top: 5rem;
            padding-bottom: 10rem;
        }
        @media(max-width:960px){
            flex-direction: column;
            align-items: center;
        }
        .Slider-characteristic.slick-initialized{
            overflow: initial;
        }
        .slider-inicio{
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            .MyCarouselHorizontal{
                position: relative;
            }
            @media(max-width:720px){
                --widthSlide: 100vw;
                width: var(--widthSlide);
            }
            @media(min-width:720px){
                --widthSlide: 40rem;
                width: var(--widthSlide);
            }
            @media(min-width:960px){
                --widthSlide: 35rem
            }
            @media(min-width:1200px){
                --widthSlide: 40rem
            }
            @media(min-width:1600px){
                --widthSlide:55rem
            }
            .slick-track{
                height: 100%;
                .slick-slide{
                    height: 100%;
                }
            }
            .slick-list{
                overflow: hidden;
                border-radius: 1.5rem;
                .slick-slide > div,.slick-slide > div > div{
                    display: flex!important;
                    height: 100%;
                }
                .slide{
                    overflow: hidden;

                }
                
                @media(max-width:720px){
                    border-radius: 0;
                    --widthSlide: 100vw;
                    width: var(--widthSlide);
                }
                @media(min-width:720px){
                    --widthSlide: 40rem;
                    width: var(--widthSlide);
                    height: 21rem;
                }
                @media(min-width:960px){
                    --widthSlide: 35rem
                }
                @media(min-width:1200px){
                    --widthSlide: 40rem;
                    height: 24rem;
                }
                @media(min-width:1600px){
                    --widthSlide:55rem;
                    height: 33rem;
                }
            }
            .MyCarouselHorizontal{
                width: var(--widthSlide);
                height: auto;
                @media(max-width:700px){
                    border-radius: 0;
                }
            }
            .owl-stage-outer{
                border-radius: 1.5rem;
                overflow: hidden;
                box-shadow: 0px 0px 10px 0px #0000001f;
                @media(max-width:700px){
                    border-radius: 0;
                }
            }
            .owl-dots{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .owl-dot{
                    width: .5rem;
                    height: .5rem;
                    border-radius: 100%;
                    background: #6e9a21;;
                    margin: 0 .2rem;
                }
                .owl-dot.active{
                    background: white;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.336) ;
                }
            }
            .slick-arrow{
                position: absolute;
                display: block;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            .slick-prev,.slick-next{
                font-size: 0;
                background: #FF4E00;
                border-radius: 100%;
                height: 2.2rem;
                width: 2.2rem;
                padding: .5rem;
                display: flex!important;
                align-items: center;
                justify-content: center;
                z-index: 3;
                border: 0;
                cursor: pointer;
                @media(max-width:740px){
                   width: 7vw;
                   min-height: 7vw;
                   height: 7vw;
                   padding: 1vw;
                }
            }
            .slick-prev{
                padding-right: .65rem;
                left: -1.1rem;
                @media(max-width:740px){
                    left: auto;
                    right: 12vw;
                    top: auto;
                    bottom: 2vw;
                    padding-right: 1.1vw;
                }
            }

            .slick-next{
                right: -1.1rem;
                padding-left: .65rem;
                @media(max-width:740px){
                    right: 3vw;
                    left: auto;
                    bottom: 2vw;
                    top: auto;
                    padding-left: 1.5vw;
                }
            }
            .slick-next::after,.slick-prev::after{
                content: "";
                display: flex!important;
                align-items: center;
                justify-content: center;
                mask-position: center;
                mask-size: contain;
                mask-repeat: no-repeat;
                background: white;
                width: 90%;
                height: 90%;
                @media(max-width:740px){
                    width: 3.1vw;
                    min-width: 3.1vw;
                    height: 70%;
                    padding: 0!important;
                }
            }
            .slick-prev::after{
                mask-image: url("../../Assets/images/iconos/signal-left.svg");
                margin-left: .1rem;
                @media(max-width:740px){
                    margin-left: 0rem;
                }
            }
            .slick-next::after{
                mask-image: url("../../Assets/images/iconos/signal-right.svg");
                margin-left: .1rem;
                @media(max-width:740px){
                    margin-left: 0rem;
                }
            }
            .slick-dots{
                display: flex !important;
                position: absolute;
                bottom: 1rem;
                left: 0;
                right: 0;
                align-items: center;
                justify-content: center;
                width: 10rem;
                margin: auto;
                @media(max-width:740px){
                    width: 5rem;
                }
                li{
                    font-size: 0;
                    border: 0;
                    width: .5rem;
                    height: .5rem;
                    margin: 0 .2rem;
                    button{
                        border-radius: 100%;
                        background: #6e9a21;
                        font-size: 0;
                        margin: 0;
                        border: 0;
                        height: 100%;
                        width: 100%;
                        box-shadow: 0px 0px 10px 0px #00000052;
                    }
                }
                .slick-active{
                    button{
                        background: white;
                    }
                }
            }
            .owl-nav{
                display: flex;
                @media(max-width:700px){
                    // display: none;
                }
                .owl-prev,.owl-next{
                    background: #FF4E00;
                    border-radius: 100%;
                    height: 2.2rem;
                    width: 2.2rem;
                    padding: .5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 3;
                    @media(max-width:700px){
                        height: 1.7rem;
                        width: 1.7rem;
                    }
                    svg{
                        height: 90%;
                    }
                    polyline{
                        stroke: white;
                        stroke-width: 6;
                    }
                }
                .owl-prev{
                    padding-right: .65rem;
                    left: -1.1rem;
                    @media(max-width:740px){
                        left: auto;
                        right: 12vw;
                        top: auto;
                        bottom: 2vw;
                    }
                }
                .owl-next{
                    right: -1.1rem;
                    padding-left: .65rem;
                    @media(max-width:740px){
                        right: 3vw;
                        left: auto;
                        bottom: 2vw;
                        top: auto;
                    }
                }
            }
            .owl-item{
                width: 100%;
                position: relative;
                display: block;
            }
            .slide{
                width: 100%;
                height: 100%;
                display: flex;
                border-radius: 1.5rem;
                overflow: hidden;
                @media(max-width:540px){
                    height: 59.45vw!important;
                    border-radius: 0;
                }
                .img{
                    width: var(--widthSlide);
                    user-select: none;
                    object-fit: cover;
                }
                iframe{
                    width: var(--widthSlide);
                    height: 100%;
                }
            }
        }
        .formulario-inicio{
            position: relative;
            width: 32rem;
            @media(max-width:700px){
                width: 100%;
            }
            @media(min-width:1400px){
                width: 32rem;
            }
            .content-whatsapp{
                z-index: 1;
            }
            .text-term{
                font-size: 1rem;
                @media(max-width:700px){
                    margin-left: .5rem;
                }
                a{
                    font-size: 1rem;
                }
            }
            .content-form{
                width: 22rem;
                @media(min-width:1400px){
                    width: 24rem;
                }
                border: 0!important;
                @media(max-width:700px){
                    width: 100%;
                    height: auto!important;
                }
                .icon{
                    bottom: 0;
                    @media(min-width:1400px){
                        width: 1.2rem;
                        height: 1.2rem;
                        left: .8rem;
                    }
                    background: #35a9df;
                }
            }
            .title,.title-3,.title-2{
                color: var(--dark);
                font-family:"GalanoGrotesque-Bold";
                font-size: 1.5rem;
                margin-bottom: 0;
                
            }
            .title-2{
                color:#35a9df;
                font-size: 2.3rem;
                line-height: 2.3rem;
            }
            .title-3{
                margin-bottom: 1.5rem;
            }
            .screen-movil{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media(max-width:740px){
                    position: relative;
                }
                @media(min-width:1400px){
                    justify-content: flex-end;
                }
                .content-form{
                    position: relative;
                    right: 0;
                    box-shadow: none;
                    @media(min-width:1400px){
                        right: -1.2rem;
                    }
                    @media(max-width:960px){
                        margin-top: 1rem;
                    }
                    .input{
                        border: 0ch;
                    }
                    .input.invalid{
                        border: 1px solid red;
                    }
                }
            }
            .content-whatsapp{
                width: 100%;
                height: 100%;
                position: relative;
                left: 0;
                right: 0;
                bottom: 0;
                
            }
            .content-form{
                background: transparent;
            }
            .input{
                -webkit-appearance: none;
                box-shadow: 2px 1px 10px -5px #00000045;
                border-radius: 50rem;
                font-size: 1.1rem;
                color: var(--dark);
                @media(max-width:700px){
                    // margin-bottom: 3vw;
                }
            }
            .contactar{
                font-size: 1.2rem;
                padding: .2rem ;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: .8rem;
                padding-top: .4rem;
                background: var(--third);
                @media(max-width:540px){
                    padding-bottom: .4rem;
                }
            }
        }
    }
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @media(max-width:740px){
            margin-bottom: 7vw;
        }
        .title-left,.title-right{
            position: absolute;
            filter: inherit;
        }
        .title-left{
            width: 3vw;
            left: -3.5vw;
            height: auto;
            top: 2vw;
            transform: scale(0);
            transition: .4s;
            transform-origin: top right;
            @media(max-width:740px){
                width: 10vw;
                left: -10vw;
            }
        }
        
        .title-right{
            right: -4vw;
            top: -2vw;
            height: auto;
            width: 4vw;
            transform: scale(0);
            transition: .4s;
            transform-origin: bottom left;
            @media(max-width:740px){
                width: 12vw;
                right: -12vw;
            }
        }
        .title-left.active,.title-right.active{
            transform: scale(1);
        }
        img{
            width: auto;
            height: 2.4rem;
            filter: drop-shadow( 0rem 0rem .3rem rgba(0, 0, 0, 0.308));
            @media(max-width:740px){
                filter: inherit;
            }
            @media(min-width:1400px){
                height: 3.4rem;
            }
            @media(min-width:700px) and (max-width:840px){
                height: 1.6rem;
            }
            @media(max-width:700px){
                height: 3.6rem;
            }
            @media(max-width:380px){
                height: 15vw;
            }
        }
    }
    .section-aliados{
        margin-top: 6rem;
        position: relative;
        @media(max-width:1400px){
            padding-left: 0;
        }
        @media(max-width:500px){
            margin-top: 3rem;
        }
        .cloud-1{
            margin: auto;
            display: flex;
            left: auto;
            @media(max-width:500px){
                width: 7rem;
            }
        }
        
        .title{
            .title-left{
                top: 68%;
                bottom: 0;            
            }
        }
        .container{
            display: flex;
            align-items: center;
            z-index: 2;
            position: relative;
            @media(max-width:1000px){
                flex-direction: column-reverse;
            }
            @media(max-width:500px){
                padding: 0;
            }
        }
        .message{
            margin-right: 3rem;
            @media(max-width:1400px){
                margin-right: 0rem;
            }
            .img{
                width: 28rem;
                @media(max-width:1400px){
                    width: 20rem;
                }
                @media(max-width:500px){
                    width: 65vw;
                }
            }
        }
        .bg3-left{
            position: absolute;
            left: 0;
            width: 31rem;
            top: 23rem;
            bottom: 0;
            margin: auto;
            @media(max-width:1400px){
                top: 13rem;
                width: 20rem;
            }
            @media(max-width:750px){
                width: 14rem;
            }
            @media(max-width:500px){
                width: 10rem;
                top: auto;
                bottom: -23vw;
            }
        }
        .bg3-right{
            position: absolute;
            right: 0;
            top: -7rem;
            width: 23rem;
            @media(max-width:1400px){
                top: -5rem;
                width: 15rem;
            }
            @media(max-width:750px){
                width: 11rem;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            @media(max-width:500px){
                top: 24vw;
                bottom: auto;
                width: 26vw;
               
            }
        }
        .title{    
            width: max-content;
            img.desktop{
                height: 5.5rem;
            }
        }
        .respaldo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            .title{
                display: flex;
                flex-direction: column;
                line-height: 1.2;
                span{
                    font-size: 1.45rem;
                    color: black;
                    @media(max-width:500px){
                        font-size: 1.2rem;
                    }
                }
            }
        }
        .content-aliados{
            width: 100%;
            display: grid;
            gap: 1.5rem;
            grid-row: max-content;
            grid-template-rows: repeat(4,max-content);
            grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
            width: 57rem;
            @media(max-width:1400px){
                grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
               
            }
            @media(max-width:1600px){
                grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
                width: 50rem;
            }
            @media(max-width:1000px){
                width: 50rem;
            }
            @media(max-width:1000px){
                width: 42rem;
            }
            @media(max-width:800px){
                width: 23rem;
            }
            @media(max-width:500px){
                display: flex;
                width: 100%;
            }
            .slick-slider{
                margin-bottom: 2rem;
                padding: 1.5rem 0;
                position: relative;
                .slick-arrow{
                    border: 0;
                    font-size: 0;
                    width: 9vw;
                    height: 9vw;
                    position: absolute;
                    background-color: transparent;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 2;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .slick-prev{
                    left: 22.5vw;
                    background-image: url("../../Assets/images/iconos/icon-left.png");
                }
                .slick-next{
                    right: 22.5vw;
                    background-image: url("../../Assets/images/iconos/icon-right.png");

                }
            }
            .slick-track{
                .slick-center{
                    .aliados{
                        transform: scale(1.3);
                        transition: .3s;
                    }
                }
                .slick-slide > div{
                    width: 50vw;
                    padding: 0 7vw;
                }
            }
            .slick-dots{
                display: none!important;
            }
            .aliados{
                width: 100%;
                .aliado{
                    width: 100%;
                    filter: drop-shadow(0rem 0rem 0.3rem rgba(0, 0, 0, 0.1));
                }
            }
        }
    }
    .atributos{
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        font-size:2rem!important;
        color: var(--green2);
        @media(min-width:1400px){
            margin-top: 2rem;
        }
        @media(max-width:700px){
            padding:0rem;
            position: relative;
        }
        .title-left{
            @media(max-width:600px){
                width: 10vw;
                left: -10vw;
                bottom: -6vw;
                top: auto!important;
            }
        }
        .title-right{
            
            @media(max-width:540px){
                top: -4vw;
                right: -1vw;
            }
            @media(max-width:360px){
                top: -4vw;
                right: 3vw;
            }
        }
        .content-atributos{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
            @media(max-width:700px){
                flex-direction: column;
                margin-top: 1rem;
            }
            @media(max-width:960px){
                flex-direction: column;
                width: 100%;
            }
            @media(max-width:540px){
                overflow: hidden;
            }
            .content-svg{
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                @media(max-width:1198px){
                    width: 85%;
                    margin-bottom: 0rem;
                    height: 28rem;
                }
                @media(min-width:1400px){
                    width: 33rem;
                }
                @media(min-width:740px){
                    width: 31%;
                    height: 17rem;
                }
                @media(min-width:960px){
                    height: 32rem;
                }
                @media(max-width:740px){
                    display: none;
                }
                // @media(max-width:540px){
                //     height: 20rem;
                //     width: 100%;
                //     overflow: hidden;
                // }
                svg{
                    width: 100%;
                }
                .img{
                    position: absolute;
                    z-index: 1;
                    opacity: 0;
                    transform: translateX(0);
                    transition: 1s;
                    transition-property: all;
                    transition-timing-function: ease;
                    @media(min-width:1400px){
                        height: 41rem;
                    }
                    @media(min-width:1200px){
                        margin-top: 2rem;
                        height: 33rem;
                    }
                    @media(min-width:740px){
                        width: 100%;
                        height: auto!important;
                    }
                    @media(max-width:940px){
                        height: 30rem;
                        height: 100%;
                    }
                    @media(max-width:1198px){
                        height: 100%;
                    }
                    @media(max-width:540px){
                        height: 20rem;
                        left: 0vw;
                        right: 0;
                        margin: auto;
                    }
                }
                .none{
                    display: none;
                }
                .img.active{
                    z-index: 2;
                    opacity: 1;
                    @media(max-width:540px){
                        right: 0;
                        margin: auto;
                        left: 0;
                    }
                }
                .img.hover{
                    visibility: visible;
                    opacity: 1;
                    z-index: 3;
                    transition-delay: 1s!important;
                    animation: 1s Moveright forwards;
                }
                .img.active.hover{
                    // animation: inherit!important;
                }
                .img.hover.move{
                    animation: inherit!important;
                }
                
                @keyframes Moveright {
                    0%{
                        transform: translateX(300px);
                        // right: -300px;
                        opacity: 0;
                    }
                    100%{
                        // right: 0;
                        transform: translateX(0px);
                        opacity: 1;
                    }
                }
                .img.move{
                    animation: 1s Moveleft linear forwards;
                }
                @keyframes Moveleft {
                    0%{
                        transform: translateX(0px);
                        opacity: 1;
                    }
                    50%{
                        transform: translateX(-300px);
                        opacity: 0;
                    }
                    100%{
                        opacity: 0;
                        transform: translateX(0px);
                    }
                }
                .moveright{
                    animation: movilRight 1s forwards;
                }
                .moveleft{
                    animation: movilLeft 2s forwards;
                }

                .moveleft2{
                    animation: movilLeft2 1s forwards;
                }
                .moveright2{
                    animation: movilRight2 2s forwards;
                }

                @keyframes movilLeft{
                    0%{
                        transform: translateX(0px);
                        opacity: 1;
                    }
                    50%{
                        transform: translateX(-300px);
                        opacity: 0;
                    }
                    100%{
                        opacity: 0;
                        transform: translateX(0px);
                    }
                }
                @keyframes movilRight{
                    0%{
                        transform: translateX(300px);
                        opacity: 0;
                    }
                    100%{
                        transform: translateX(0px);
                        opacity: 1;
                    }
                }
                // left to right
                @keyframes movilLeft2{
                    0%{
                        transform: translateX(-300px);
                        opacity: 0;
                    }
                    100%{
                        transform: translateX(0px);
                        opacity: 1;
                    }   
                }
                @keyframes movilRight2{
                    0%{
                        transform: translateX(0px);
                        opacity: 1;
                    }
                    50%{
                        transform: translateX(300px);
                        opacity: 0;
                    }
                    100%{
                        opacity: 0;
                        transform: translateX(0px);
                    }
                }
            }
            .content-items{
                width: 60%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                @media(max-width:740px){
                    width: 100%;
                    overflow: hidden;
                    height: 117vw;
                }
                @media(min-width:1400px){
                    width: 46rem;
                }
                @media(min-width:740px) and (max-width:960px){
                    width: 100%;
                }
                .slick-slide{
                    transition: .5s;
                }
                .slick-center{
                    transform: scale(1.06);
                }
                .item{
                    border-radius: 1rem;
                    margin:1.3rem 2rem;
                    box-shadow: 0px 0px 18px -9px #00000054;
                    overflow: hidden;
                    width: 22%;
                    height: 95vw;
                    background:white;
                    transition: .5s;
                    z-index: 10;
                    cursor: inherit;
                    
                    @media(max-width:740px){
                        width: 62vw!important;
                        margin:1.3rem .8rem;
                    }
                    @media(min-width:740px){
                        height: 15rem;
                    }
                    @media(min-width:740px){
                        width: 19%;
                        height: 11rem;
                    }
                    @media(min-width:960px){
                        height: 13rem;
                        width: 30%;
                    }
                    @media(min-width:1200px){
                        width: 22%;
                    }
                    @media(min-width:1400px){
                        width: 11rem;
                        height: 15rem;
                    }
                    .img{
                        padding: 12%;
                        height: 70%;
                        width: 100%;
                        @media(max-width:740px){
                            padding: 0%;
                            height: 100%;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                            @media(max-width:740px){
                                object-fit: cover;
                            
                            }
                        }
                    }
                    .title{
                        width: 100%;
                        height: 30%;
                        background: #80b428;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding-bottom: .7rem;
                        font-size: 1.1rem;
                        .t-title{
                            font-family: "GalanoGrotesque-Medium";
                            color: white;
                            padding: 0 1rem;
                            line-height: 1.3rem;
                            text-align: center;
                            @media(max-width:540px){
                                font-size: .7rem;
                                line-height: .9rem;
                                padding: .5rem;
                            }
                            @media(max-width:740px){
                                padding-top: .5rem;
                            }
                            @media(min-width:740px){
                                font-size: .7rem;
                                line-height: .9rem;
                            }
                            @media(min-width:960px){
                                font-size: 1rem;
                                line-height: 1.3rem;
                            }
                            @media(min-width:1400px){
                                font-size: 1.3rem;
                                line-height: 1.5rem;
                            }
                        }
                        .sub-title{
                            font-family: "GalanoGrotesque-Medium";
                            color: white;
                            padding: 0 1rem;
                            line-height: 1.3rem;
                            font-size: .8rem;
                            @media(max-width:540px){
                                font-size: .6rem;
                                line-height: .9rem;
                                padding: .5rem;
                            }
                            @media(min-width:740px){
                                line-height: .6rem;
                                font-size: .7rem;
                            }
                            @media(min-width:960px){
                                line-height: 1.3rem;
                            }
                            @media(max-width:740px){
                                padding-top: .5rem;
                            }
                            @media(min-width:1400px){
                                font-size: 1rem;
                            }
                        }
                    }
                }
                .item:hover{
                    transform: scale(1.04);
                    box-shadow: 0px 0px 18px -9px #00000094;
                }
                .item.active{
                    transform: scale(1.04);
                    box-shadow: 0px 0px 18px -9px #00000094;
                }
                .owl-dots{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .owl-dot{
                        width: .5rem;
                        height: .5rem;
                        margin: 0 .1rem;
                        border-radius: 100%;
                        background: var(--Primary);
                        opacity: .5;
                    }
                    .owl-dot.active{
                        opacity: 1;
                    }
                }
                
                .slick-arrow{
                    display: flex!important;
                    width: 10vw;
                    height: 10vw;
                    background: #ECCA20;
                    z-index: 10;
                    border-radius: 100%;
                    position: absolute;
                    font-size: 0;
                    border: 0;
                    align-items: center;
                    justify-content: center;
                }
                .slick-next{
                    top: 50vw;
                    right: 13vw;
                }
                .slick-prev{
                    top: 50vw;
                    right: 78vw;
                }
                .slick-next::after,.slick-prev::after{
                    content: "";
                    position: relative;
                    width: 5vw;
                    height: 5vw;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    background: white;
                }
                .slick-next::after{
                    mask-image: url("../../Assets/images/iconos/signal-right.svg");
                    margin-left: 0.5vw;
                }
                .slick-prev::after{
                    margin-right: 1.5vw;
                    mask-image: url("../../Assets/images/iconos/signal-left.svg");
                }
            
                .slick-dots{
                    display: flex!important;
                    width: 100%;
                    position: absolute;
                    top: 50vw;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    bottom: 4vw;
                    @media(max-width:740px){
                        position: relative;
                        top: -7vw;
                    }
                    @media(max-width:500px){
                        top: -10vw;
                    }
                    li{
                        opacity: .5;
                        button{
                            width: 0.5rem;
                            height: 0.5rem;
                            background: var(--Primary);
                            border-radius: 100%;
                            font-size: 0;
                            border: 0;
                            margin-right: .5rem;
                        }
                    }
                    li.slick-active{
                        opacity: 1;
                    }
                }
            }
        }
    }
    .Galery{
        padding-top: 0;
        margin-top: 7rem!important;
        position: relative;
        // margin-bottom: 9rem;
        @media(min-width:700px){
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        @media(max-width:740px){
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .title-left{
            @media(max-width:600px){
                width: 10vw;
                left: -10vw;
                bottom: -6vw;
                top: auto!important;
            }
        }
        .title-right{
            @media(max-width:540px){
                top: -4vw;
                right: -5vw;
            }
        }
        .title-2{
            margin-bottom: 2vw;
            @media(max-width:740px){
                margin-bottom: 10vw;
            }
        }
        .title{
            margin-bottom: 2rem;
        }
        .container-galery{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            .content-items{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 2rem;
                height: auto;
                background: #80b428;
                border-radius: .7rem;
                padding: .4rem 1.5rem;
                .item{
                    font-family: "GalanoGrotesque-Medium";
                    color: white;
                    font-size: 1.1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 2rem;
                    cursor: pointer;
                    padding-bottom: .3rem;
                    @media(max-width:1200px){
                        font-size: 1rem;
                        padding: 0 1rem;
                        padding-bottom: .5rem;
                        padding-top: .3rem;
                    }
                    img{
                        width: 4vw;
                    }
                    .active{
                        display: none;
                    }
                }
                .item.active{
                    font-family: "GalanoGrotesque-Bold";
                    color: white;
                    .inactive{
                        display: none;
                    }
                    .active{
                        display: block;
                    }
                }
            }
            $width:58vw;
            .content-multimedia{
                width: $width;
                height: 32vw;
                position: relative;
                display: block;
                left: 0;
                right: 0;
                margin: auto;
                box-shadow: 0px 0px 30px -11px #00000017;
                .cont-gallery{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    z-index: 0;
                    transition: .5s linear;
                    opacity: 0;
                    border-radius: 1.5rem;
                    .owl-stage-outer{
                        border-radius: 1.5rem;
                    }
                    .play{
                        width: 6rem!important;
                        height: 6rem!important;
                        transition: .4s;
                        cursor: pointer;
                    }
                    .play:hover{
                        transform: scale(1.04);
                    }
                    .feature{
                        width: 100%;
                        height: 3.2rem;
                        position: absolute;
                        bottom: 0;
                        background: var(--secondary);
                       
                        .background-feature{
                            position: absolute;
                            width: 100%;
                            bottom: 3.2rem;
                           
                        }
                        .content-title{
                            padding: 0 2vw;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: flex-start;
                            padding-top: .2vw;
                            justify-content: space-between;
                        }
                        .title1,.title2{
                            color: white;
                            font-family: "Raleway-Medium";
                            font-size: .9vw;
                        }
                    }
                    .MyCarouselHorizontal{
                        width: 100%;
                        height: 100%;
                    }
                    .diap{
                        width: 100%;
                        height: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: $width;
                            height: 100%;
                            object-fit: cover;
                            object-position: bottom right;
                            position: relative;
                        }
                    }
                    .MyCarouselHorizontal{
                        .owl-nav{
                            display: flex;
                            polyline{
                                stroke: white;
                            }
                            .owl-prev,.owl-next{
                                width: 3.5rem;
                                height: 3.5rem;
                                padding: 1.2rem;
                                border-radius: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: 0px 0px 13px -5px #0000001f;
                                background: #FF4E00;
                            }
                            .owl-prev{
                                left: -1.7rem;
                            }
                            .owl-next{
                                right: -1.7rem;
                            }
                        }
                    }
                    .owl-dots{
                        position: absolute;
                        right: 0;
                        left: 0;
                        bottom: 2rem;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .owl-dot{
                            width: .5rem;
                            height: .5rem;
                            border-radius: 100%;
                            background: #80808057;
                            margin: 0 .2rem;
                            // border: 1px solid transparent;
                            // box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.338);                        
                        }
                        .owl-dot.active{
                            background: white;
                        }
                    }
                }
                .cont-gallery.proyecto{
                    // overflow: hidden;
                }
                .cont-gallery.ubicacion{
                    overflow: hidden;
                }
                .cont-gallery.active{
                    z-index: 1;
                    opacity: 1;
                }
            }
        }
    }
    .calcula-cuotas{
        margin-top: 2rem;
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        .cloud-left{
            position: absolute;
            left: 10rem;
            top: 10rem;
            width: 15rem;
            @media(max-width:1200px){
                width: 7rem;
                top: 0rem;
            }
        }
        .cloud-right{
            width: 15rem;
            position: absolute;
            right: 10rem;
            top: 20rem;
        }
        .point-left{
            position: absolute;
            left: 10rem;
            top: 30rem;
            @media(max-width:740px){
                display: none;
            }
        }
    }
}
.start-1{
    width: 70%!important;
    height: max-content!important;
    top: -7rem;
    position: absolute;
}
.content-gallery{
    background-image: url("../../Assets/images/iconos/bg-2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
}
.star-form{
    position: absolute;
    left: 10%;
    top: 16vw;
    width: 13vw;
    @media(max-width:1400px){
        top: 20vw;
        width: 19vw;
    }
}