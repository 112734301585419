.place{
    mask-image: url("../../Assets/images/iconos/place-footer.svg");
}
.whatsapp{
    mask-image: url("../../Assets/images/iconos/whatsapp-footer.svg");
}
.email{
    mask-image: url("../../Assets/images/iconos/email-footer.svg");
}
.footer{
    display: flex;
    flex-direction: column;
    position: relative;
    *{
        font-family: "GalanoGrotesque-Medium";
    }
    .contain{
        width: 100%;
        padding: 1.2rem 1rem;
        background: var(--dark);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        @media(max-width:950px){
            flex-direction: column;
            justify-content: center;
        }
        @media(max-width:740px){
            padding: 1.2rem 3rem;
            width: 100%;
            align-items: center;
        }
        @media(max-width:540px){
            padding: 1.2rem 2rem;
        }
        .item{
            text-align: center;
        }
    }
    .container-links{
        display: flex;
        @media(max-width:950px){
            flex-direction: column;
            align-items: center;
            .link{
                display:flex;
                flex-direction: column;
            }
        }
        @media(max-width:500px){
            .link,.link a{
                font-size: .9rem!important;
            }
            .link a:first-child{
                margin-bottom: .5rem;
            }
        }
        .booking{
            margin-left: 1rem;
            width: 6rem;
            @media(max-width:950px){
                margin:1.5rem 0 ;
            }
            img{
                width: 100%;
            }
        }
        
    }
    .brand{
        @media(max-width:740px){
            width: 56vw;
            position: relative;
            margin: auto;
            margin-bottom: 12vw;
        }
    }
    .content-links{
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background: #fbfbfb;
        box-shadow: 0px 0px 9px 0px #e8e8e8;
        @media(max-width:740px){
            flex-wrap: wrap;
            padding: 1rem 1.5rem;
            gap: .5rem;
        }
        @media(max-width:500px){
            align-items: flex-start;
        }
        .img-background{
            width: 75%;
            position: absolute;
            bottom: 0;
            left: 0;
            @media(max-width:740px){
                width: 100%;
            }
        }
        .brand{
            z-index: 1;
            width: 10rem;
            margin-bottom: .3rem;
            position: absolute;
            right: 3rem;
            @media(max-width:540px){
                right: -1.5rem;
            }
            @media(max-width:960px){
                width: 7rem;
            }
        }
        .column{
            display: flex;
            flex-direction: column;
            font-size: 1.2rem;
            color: var(--dark);
            margin-right: 2.5rem;
            @media(max-width:960px){
                font-size: 1rem;
            }
            @media(max-width:540px){
                margin: 0;
                font-size: .9rem;
                line-height: 1.2rem;
            }
        }
        .link{
            z-index: 1;
            color: var(--dark);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            font-family: "GalanoGrotesque-SemiBold";
            height:  max-content;
            @media(max-width:960px){
                font-size: 1rem;
            }
            @media(max-width:740px){
                font-size: .9rem;
                width: 100%!important;
                justify-content: flex-start;
            }
            @media(max-width:540px){
                font-size: .8rem;
            }
            .icon{
                width: 1.2rem;
                min-width: 1.2rem;
                height: 2rem;
                background: var(--third);
                mask-position: center;
                mask-repeat: no-repeat;
                margin-right: .5rem;
                margin-left: 2rem;
                @media(max-width:960px){
                    margin-left: .8rem;
                }
                @media(max-width:540px){
                    margin-left: 0rem;
                }
                @media(max-width:740px){
                    width: 1rem;
                    min-width: 1rem;
                    height: 2rem;
                }
                @media(max-width:540px){
                    mask-size: 80% 80%;
                    width: 1rem;
                    height: 1.4rem;
                    min-width: 1rem;
                    margin-bottom: .2rem;
                }
            }
            .place{
                @media(max-width:540px){
                    margin-bottom: .7rem;
                }
            }
        }
        .text-link{
            font-family: "GalanoGrotesque-SemiBold";
            padding-bottom: .5rem;
            
            @media(max-width:540px){
                padding-bottom: 0rem;
            }
        }
        .info{
            font-family: "GalanoGrotesque-Light";
            font-size: 1.3rem;
            position: relative;
            top: -.5rem;
            @media(max-width:540px){
                text-align: left;
                top: -.2rem;
                font-size: 1rem;
                padding-top: .2rem;
                font-size: 1rem;
            }
        }
        .link-padova{
            z-index: 1;
            position: absolute;
            right: 3rem;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            align-items: center;
        }
        .link-place{
            margin-left: 3rem;
            // margin-bottom: 0.7rem;
            width: max-content!important;
            height: 2rem;
            @media(max-width:960px){
                margin-left: 1rem;
                height: max-content;
            }
            @media(max-width:740px){
                margin-left: 0;
            }
            @media(max-width:500px){
                width: 8rem !important;
                text-align: left;
                display: flex;
                align-items: flex-start;
                a{
                    font-size: .8rem!important;
                    line-height: 1.2;
                }
            }
        }
    }
    .content-footer{
        background: var(--dark);
        position: relative;
        display:flex;
        justify-content: center;
        @media(max-width:740px){
            display: flex;
        }
        .container{
            width: 100%;
        }
        .item{
            width: 21rem;
            text-align: right;
        }
    }
    .link{
        display:flex;
        flex-direction: column;
    }
    .link,.sub-title{
        font-size: .9rem;
        @media(max-width:950px){
            text-align: center;
        }
        @media(max-width:740px){
            font-size: .9rem;
        }
        @media(max-width:540px){
            font-size: 0.7rem;
            text-align: center;
            display: block;
        }
    }
    .sub-title{
        @media(max-width:540px){
            margin-top: 0rem;
        }

    }
    .link{
        margin-bottom: .0rem;
        .column{
            font-family: "GalanoGrotesque-SemiBold";
        }
    }
    .link-facebook{
        position: absolute;
        width: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 20%;
        z-index: 1;
        @media(max-width:740px){
            position: relative;
            width: 25%;
            left: 0;
            margin: 0!important;
            background: var(--primary);
        }
        img{
            width: 100%;
            @media(max-width:740px){
                width: 67%;
            }
        }
    }
    .line{
        @media(max-width:740px){
            display: block;
        }
    }
}