:root{
    --Primary: #0B6B35;
    --Secondary: #003061;
    --third:#FF4E00;
    --dark:#191919;
    --greenLigth:#80B226;
    --green2:#008F40;
    --navbar:"hidden";
    --green:#80D3B9;
    --azul:#1D3360;
    --gray:#808080;
} 
.b-Primary{
    background: var(--Primary);
}
.b-greenLight{
    background: var(--greenLigth);
}
//Sliders
.slick-track{
    display: flex;
}
.slick-list, .slick-slider {
    position: relative;
    display: block;
    height: 100%;
}
.slick-slider {
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.lg-autoplay-button{
    display: none!important;
}
.owl-item{
    // width: auto!important;
}
.lg-autoplay-button{
    display: none!important;
}
.swal2-styled.swal2-confirm {
    background-color: var(--third) !important;
}
.owl-nav{
    display: none;
    .owl-prev,.owl-next{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 3vw;
        height: 3vw;
        
        svg{
            height: 100%;
        }
    }
    .owl-prev{left: 5%;cursor: pointer;}
    .owl-next{right: 5%;cursor: pointer;}
}
.owl-stage{
    display: flex;
    flex-direction: row;
    height: 100%;
}
.owl-stage-outer{
    overflow: hidden;
    position: relative;
    height: 100%;
    overflow: hidden;
}
.owl-carousel{
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "aldrich";
}
// end Slider
.Section{
    width: 100%;
    height: var(--height);
    min-height: 700px;
    position: relative;
}
.flex-row{
    display: flex;
    flex-direction: row;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.text-center{
    text-align: center;
}
.p-absolute{
    position: absolute;
}
.mb-1{
    margin-bottom: 1rem;
}
.mb-2{
    margin-bottom: 2rem;
}
.mb-3{
    margin-bottom: 3rem;
}
.mb-4{
    margin-bottom: 4rem;
}
.mr-1{
    margin-right: .5vw;
}
.img-expand{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.desktop{
    display: none!important;
}
.movil{
    display: block!important;
}
@media(min-width:700px){
    .desktop{
        display: flex!important;
    }
    .movil{
        display: none!important;
    }
}
.lg-thumb-item.active{
    border-color: var(--Primary)!important;
}
//Colores
.Primary{
    color: var(--Primary);
}
.Secondary{
    color: var(--Secondary);
}
.b-greenLigth{
    background: var(--greenLigth);
}
.b-third{
    background: var(--third);
}
.primary{
    color: var(--Primary);
}
.third{
    color: var(--Primary);
}
.white{
    color: white;
}

//
.pointer{
    cursor: pointer;
}
.img-fluid{
    width: 100%;
}

.d-block{
    display: block;
}
.mx-auto{
    margin: 0 auto 0 auto;
}
// style size dimentions
.container{
    padding: 0 1rem;
    @media(min-width:720px){
        max-width: 720px;
    }
    @media(min-width:960px){
        max-width: 960px;
    }
    @media(min-width:1200px){
        max-width: 1200px;
    }
}
// iconos
// .left{
//     mask: url("../../../Assets/images/iconos/signalLeft.svg");
//     mask-size: contain;
//     mask-repeat: no-repeat;
//     mask-position: center;
// }
// .right{
//     mask: url("../../../Assets/images/iconos/signalRight.svg");
//     mask-size: contain;
//     mask-repeat: no-repeat;
//     mask-position: center;
// }

// style gallery
.lg-outer #lg-share:after,.lg-group:after,
.lg-toolbar .lg-close:after,
.lg-toolbar .lg-download:after,
.lg-fullscreen:after,
#lg-zoom-in:after,
#lg-zoom-out:after,
.lg-actions .lg-prev:after,
.lg-actions .lg-next:before,
#lg-counter-current,
#lg-counter-all{
    color: var(--third)!important;
}
#lg-counter{
    font-size: 1.2rem;
    color: white!important;
    @media(max-width:740px){
        color: var(--third)!important;
    }
}
.lg-backdrop::after{
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(255, 255, 255, 0.45);
}
.lg-autoplay-button{
    display: none!important;
}
#lg-counter-current,
#lg-counter-all,#lg-counter{
    font-family: "GalanoGrotesque-Medium";
    @media(max-width:740px){
        color: white!important;
    }
}
.lg-actions .lg-prev,.lg-actions .lg-next{
    background: white!important;
    border-radius: 100%!important;
    height: 2.5vw;
    width: 2.5vw;
    box-shadow: 0 0 10px 0px #0000001c;
    @media(max-width:740px){
        background: rgba(255, 255, 255, 0.562)!important;
    }
}
.lg-toolbar.lg-group{
    background-color: transparent!important;
}
#lg-counter-all{
    margin-left: .3rem;
    color: white!important;
}
#lg-counter-current{
    margin-right: .3rem;
    color: white!important;
}

.lg-backdrop.in{
    background-image: url("../../images/inicio/background-gallery.jpg")!important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media(max-width:740px){
        background-image: url("../../images/inicio/background-gallery-movil.jpg")!important;
    }
}
.lg-icon{
    display: flex!important;
    align-items: center;
    justify-content: center;
    position: relative;
    @media(max-width:740px){
        width: 2.5rem!important;
        height: 2.5rem!important;
        background: white!important;
        border-radius: 100%;
        margin-top: 1rem;
        margin-right: .5rem;
        box-shadow: 0 0 10px 0px #0000001c!important;
    }
}
.lg-toogle-thumb{
    display: none!important;
}
.lg-icon::after{
    font-size: 1.3vw;
    @media(max-width:740px){
        font-size: 5.3vw;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.lg-autoplay-button{
    display: none!important;
}
#lg-counter{
    @media(max-width:740px){
        height: 3.5rem!important;
        display: flex!important;
        justify-content: flex-start;
        align-items: center;
    }
}
.lg-sub-html{
    @media(max-width:740px){
        display: none!important;
    }
}

.cloud-1{
    position: relative;
    top: -2rem;
    left: 15%;
}
.w-full{
    width: 100%;
    // background-image: url("../../images/iconos/b-1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.Gallery,.section-aliados{
    .cloud-left{
        position: absolute;
        left: 10rem;
        top: 10rem;
        width: 15rem;
        @media(max-width:1300px){
            width: 9rem;
            left: 2rem;
        }
        @media(max-width:500px){
            width: 7rem;
        }
    }
    .cloud-right{
        width: 15rem;
        position: absolute;
        right: 10rem;
        top: 10rem;
        z-index: 0;
        @media(max-width:1300px){
            width: 9rem;
            right: 3rem;
        }
        @media(max-width:500px){
            width: 7rem;
        }
    }
}
.cloud-gallery-1{
    position: absolute;
    top: 7%;
    bottom: 0;
    margin: auto;
    left: 3rem;
    width: 10rem;
}
.cloud-gallery-2{
    position: absolute;
    top: 13%;
    bottom: 0;
    margin: auto;
    right: 3rem;
    width: 10rem;
}