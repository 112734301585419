.workprogress{
    display: flex;
    justify-content: center;
    flex-direction: column;
    .container{
        margin: auto;
        width: 100%;
        z-index: 10;
        position: relative;
        @media (min-width: 1400px){
            max-width: 1200px!important;
        }
        @media (min-width: 1000px){
            max-width: 1000px!important;
        }

    }
    .owl-nav.disabled{
        display: none;
    }
    .owl-nav{
                display: flex;
                @media(max-width:700px){
                    // display: none;
                }
                .owl-prev,.owl-next{
                    background: #FF4E00;
                    border-radius: 100%;
                    height: 2.5rem;
                    width: 2.5rem;
                    padding: .5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 3;
                    @media(max-width:700px){
                        height: 2rem;
                        width: 2rem;
                    }
                    svg{
                        height: 90%;
                    }
                    polyline{
                        stroke: white;
                        stroke-width: 6;
                    }
                }
                .owl-prev{
                    padding-right: .65rem;
                    left: 1rem;
                    // @media(max-width:740px){
                    //     left: auto;
                    //     right: 12vw;
                    //     top: auto;
                    //     bottom: 2vw;
                    // }
                }
                .owl-next{
                    right: 1rem;
                    padding-left: .65rem;
                    // @media(max-width:740px){
                    //     right: 3vw;
                    //     left: auto;
                    //     bottom: 2vw;
                    //     top: auto;
                    // }
                }
            }
    .left-top{
        width: 13rem;
        position: absolute;
        top: 0;
        left: 0;
        @media(max-width:1200px){
            width: 8rem;
        }
        @media(max-width:500px){
            width: 4rem;
            top: -1rem;
        }
    }
    .left-middle{
        position: absolute;
        width: 10rem;
        top: 50%;
        @media(max-width:1400px){
            width: 8rem;
        }
        @media(max-width:500px){
            top: 45%;
            width: 5rem;
        }
    }
    .bottom{
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    .right-figure{
        position: absolute;
        right: 0;
        width: 18vw;
        top: 10vw;
        @media(max-width:1000px){
            top: 20%;
        }
    }
    .banner{
        width: 100%;
        height: 50rem;
        position: relative;
        @media(max-width:1400px){
            height: 40rem;
        }
        @media(max-width:1000px){
            height: 35rem;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media(max-width:500px){
                object-position: center;
            }
        }
        .status{
            position: absolute;
            width: 18rem;
            object-fit: contain;
            top: 4rem;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            @media(max-width:500px){
                width: 12rem;
            }
        }
    }
    .content-progress{
        padding: 4rem 0;
    }
    .title{
        font-family: "GalanoGrotesque-Medium";
        font-weight: 100;
        color: var(--third);
        font-size: 3.20rem;
        letter-spacing: 0.1rem;
        text-align: center;
        margin-bottom: 3rem;
        @media(max-width:600px){
             font-size: 2.2rem;
             margin-bottom: 3rem;
        }
        @media(max-width:500px){
            font-size: 1.7rem;
              letter-spacing: 1px;
        }
    }
    .section-sliders{
        overflow: hidden;
        position: relative;
        width: 100%;
        background-image: url("../../Assets/images/workprogress/background.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .c-third{
        color: var(--third);
    }
    .section-progress{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
        @media(max-width:1000px){
            flex-direction: column;
        }
        .content-text{
            width: 100%;
        }
        .text{
            font-family: "GalanoGrotesque-Medium";
            font-size: 1.6rem;
            width:17rem;
            @media(max-width:1000px){
                width: 23rem;
                text-align: center;
                margin-bottom: 2rem;
            }
            @media(max-width:500px){
                line-height: 1.2rem;
                margin-bottom: 1rem;
                font-size: 1.1rem;
                width: 15rem;
                text-align: left;
            }
            span{
                font-family: "GalanoGrotesque-Medium";
            }
        }
    }
    .owl-dots{
        display:flex;
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        margin: auto;
        width: max-content;
        .owl-dot{
            width: 8px;
            height: 8px;
            border-radius: 10rem;
            margin: 3px;
            opacity: .3;
            background-color: var(--third);
            &.active{
                opacity: 1;
            }
        }
    }
    .gallery-avance{
        --heightAvance: 30rem;
        --widthAvance : 45rem;
        @media(min-width:1000px){
            --heightAvance: 23rem;
            --widthAvance : 38rem;
        }
        @media(max-width:800px){
            --heightAvance: 18rem;
            --widthAvance : 32rem;
        }
        @media(max-width:500px){
            --heightAvance: 50vw;
            --widthAvance : calc(100vw - 2rem);
        }
        position: relative;
        width: var(--widthAvance);
        height: var(--heightAvance);
        .MyCarouselHorizontal{
            width: 100%;
            height: 100%;
            border-radius: 1.5rem;
            overflow: hidden;
            .diap{
                width: var(--widthAvance);
                height: var(--heightAvance);
                position: relative;
                .lupa{
                    width: 3.9rem;
                    height: 3.9rem;
                    @media(max-width:1400px){
                        width: 3.4rem;
                        height: 3.4rem;
                    }
                    @media(max-width:500px){
                        width: 2.8rem;
                        height: 2.8rem;
                    }
                }
                .img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}