.section-stage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 4rem;
    @media(max-width:500px){
        margin-bottom: 4rem;
        padding: 0;
    }
    .container{
        @media(max-width:500px){
            padding: 0;
            width: calc(100vw - 2rem);
        }
    }
    .title{
        margin-bottom: 5.5rem;
        font-size: 2rem;
        color: var(--green2);
        @media(max-width:1400px){
            margin-bottom: 4.5rem;
        }
        @media(max-width:500px){
            margin-bottom: 4vw;
            font-size: 1.4rem;
        }
        .title-left{
            top: 76%;
        }
        img{
            @media(max-width:500px){
                height: 13vw;
            }
        }
    }
    .img-project{
        width: 100%;
        height: 0;
        object-fit: cover;
        display: flex;
        align-items: flex-start;
        position: relative;
        overflow: hidden;
        transition: .7s;
        .content-img{
            border-radius: 1rem;
            position: relative;
            @media(max-width:500px){
                &::after{
                    content: "";
                    position: absolute;
                    border-radius: 1.5rem;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.5);
                    pointer-events: none;
                }
                &::before{
                    content: "";
                    position: absolute;
                    border-radius: 1.5rem;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: 17vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url("../../Assets/images/iconos/hand.png");
                    z-index: 2;
                    pointer-events: none;
                }
                &.active{
                    &::before{
                        transition: .5s;
                        opacity: 0;
                    }
                    &::after{
                        transition: .5s;
                        opacity: 0;
                    }
                }
            }
            .overflow{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: flex-start;
                overflow: hidden;
                .d-flex{
                    display: flex;
                    @media(max-width:500px){
                        overflow: scroll;
                    }
                }
                @media(max-width:500px){
                    width: calc(100vw - 2rem);
                    border-radius: 1.5rem;
                }
            }
        }
        .shadow{
            position: absolute;
            width: 100%;
            background: linear-gradient(180deg,transparent 0,#fff 50%,#fff);
            height: 4rem;
            bottom: -2rem;
            pointer-events: none;
            z-index: 1;            
        }
        img{
            width: 100%;
            height: auto;
            @media(max-width:500px){
                width: auto;
                height: 100vw;
            }
        }
        .button{
            position: absolute;
            bottom: 0;
            margin-bottom: 0;
            left: 0;
            right: 0;
            width: max-content;
            margin: 0 auto;
            transform: translateY(50%);
            z-index: 2;
        }
        &.active{
            transition-delay: .5s;
            height: 47rem;
            @media(max-width:1600px){
                height: 40rem;
            }
            @media(max-width:1400px){
                height: 30rem;
            }
            @media(max-width:900px){
                height: 25rem;
            }
            @media(max-width:550px){
                height: 17rem;
            }
            @media(max-width:500px){
                height: 108vw;
            }
        }
    }
    .button{
        cursor: pointer;
        font-size: 1.4rem;
        letter-spacing: 1px ;
        border-radius: 0.4rem;
        background-color: #35a9df;
        color: white;
        font-family: "GalanoGrotesque-SemiBold";
        white-space: nowrap;
        padding: 0.4rem 1.5rem;
        padding-bottom: 0.8rem;
        height: 3.2rem;
        width: max-content;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        @media(max-width:1400px){
            height: 3rem;
        }
        @media(max-width:500px){
            font-size: 1rem;
            padding-bottom: 0.4rem;
            height: 11.5vw;
        }
    }
    .less{
        @media(max-width:500px){
            width: 40vw!important;
        }
    }
    .more-information {
        opacity: 1;
        transition: .5s;
        &.inactive{
            pointer-events: none;
            opacity: 0;
            // animation: 1s inactive forwards;
            // @keyframes inactive{
            //     0%{
            //         opacity: 1;
            //         position: relative;
            //     }
            //     100%{
            //         opacity: 0;
            //     }
            // }
        }
    }
}
.content-stage{
    --color1:#f5d400;
    --color2:#008A40;
    --color3:#D91118;
    --color4:#35a9df;
    --color5:#f55115;
    display: flex;
    justify-content: center;
    z-index: 2;
    margin-bottom: 4rem;
    position: relative;
    @media(max-width:1100px){
        flex-wrap:wrap;
        gap: 4rem 0;
    }
    @media(max-width:900px){
        margin-bottom: 2rem;
    }
    @media(max-width:500px){
        margin-bottom: 3rem;
        gap: 2.5rem 1rem;
        width: 100%;
        .slick-slide{
            margin: 0 ;
            & > div{
                padding: 8vw 4vw;
            }
        }
        .slick-track{
            position: relative;
            // left: 12.7vw;
        }
        .slick-slider {
            width: 100%!important;
        }
        .slick-list{
            // padding: 0!important;
            // padding: 0px !important;
            width: 100%!important;
        }
        .slick-arrow{
            position: absolute;
            top: 0;
            margin: auto;
            bottom: 0;
            width: 2rem;
            height: 2rem;
            background-color: black;
            border-radius: 30rem;
            border:0px;
            z-index: 2;
        }
        .slick-next{
            right: 19vw;
            font-size: 0;
            background-color: #0169c2;
            &::after{
                content: "";
                mask-image: url("../../Assets/images/iconos/signal-right.svg");
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                margin-left: .1rem;
                position: absolute;
                width: 50%;
                height: 50%;
                background: white;
                margin: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        .slick-prev{
            font-size: 0;
            left: 19vw;
            background-color: #0169c2;
            &::after{
                content: "";
                mask-image: url("../../Assets/images/iconos/signal-left.svg");
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                margin-left: .1rem;
                position: absolute;
                width: 50%;
                height: 50%;
                background: white;
                margin: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }
    .bg1{
        background-color: var(--color1);
        .checked{
            border-color: var(--color1);
        }
        .mask-center{
            background-color: var(--color1);
        }
    }
    .bg2{
        background-color: var(--color2);
        .checked{
            border-color: var(--color2);
        }
        .mask-center{
            background-color: var(--color2);
        }
    }
    .bg3{
        background-color: var(--color3);
        .checked{
            border-color: var(--color3);
        }
        .mask-center{
            background-color: var(--color3);
        }
    }
    .bg4{
        background-color: var(--color4);
        .checked{
            border-color: var(--color4);
        }
        .sub-title{
            font-size: 1.5rem !important;
            line-height: 1.2;
            @media(max-width:500px){
                font-size: 1.2rem !important;
            }
        }
        .mask-center{
            background-color: var(--color4);
        }
    }
    .bg5{
        background-color: var(--color5);
        width: 19em!important;
        @media(max-width:500px){
             width: 100%!important;
        }
        .checked{
            border-color: var(--color5);
        }
        .mask-center{
            background-color: var(--color5);
        }
        .sub-title{
            font-size: 1.6rem !important;
            line-height: 1.2;
            @media(max-width:500px){
                font-size: 1.2rem !important;
            }
        }
    }
    .bg3{
        @media(max-width:500px){
        
            .sell{
                .bold{
                    font-size: 1.2em!important;
                }
            }
        }
    }
    .c1{
        color: var(--color1);
    }
    .c2{
        color: var(--color2);
    }
    .c3{
        color: var(--color3);
    }
    .c4{
        color: var(--color4);
    }
    .c5{
        color: var(--color5);
    }
    .stage{
        font-size: 0.8rem;
        box-shadow: 0px 0px 10px 0px #00000045;
        border-radius: 1rem;
        position: relative;
        margin: 0 1rem;
        @media(max-width:1400px){
            font-size: .8rem;
        }
        @media(max-width:900px){
            font-size: .9rem;
        }
        @media(max-width:800px){
            font-size: .7rem;
        }
        @media(max-width:600px){
            margin: 0 .5rem;
        }
        @media(max-width:500px){
            font-size: 2.4vw;
            margin: 0;
            width: 50vw!important
        }
        .border{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            pointer-events: none;
        }
        .head-stage{
            display: flex;
            flex-direction: column;
            border-radius: 1rem 1rem 0 0;
            color: white;
            padding: 1.7em;
            padding-top: 2.5em;
            padding-bottom: 1.3em;
            position: relative;
            height: 9em;
            width: 12em;
            justify-content: center;
            align-items: center;
            @media(max-width:500px){
                padding: 3.5vw;
                padding-top: 7vw;
                border-radius: 3vw 3vw 0 0;
                height: 9.2em;
                width: 100%;
            }
            .sell{
                align-items: center;
                display: flex;
                flex-direction: column;
                font-size: 1.7em;
                font-size: 1.4em;
                justify-content: center;
                line-height: 1.2;
                padding-bottom: 0rem;
                @media(max-width:500px){
                    line-height: 2em;
                    padding-bottom: .3rem;
                }
                .bold{
                    // font-size: 3rem;
                    line-height: 1.2;
                    @media(max-width:700px){
                        line-height: 1.1rem;
                        font-size: 1.1rem;
                    }
                    @media(max-width:500px){
                        line-height: 1.4rem;
                        font-size: 1.5em;
                    }
                }
            }
            .checked{
                width: 3.5rem;
                height: 3.5rem;
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                transform: translateY(-50%);
                z-index: 2;
                border-radius: 100%;
                border-width: 5px;
                border-style: solid;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                @media(max-width:1400px){
                    width: 3.5rem;
                }

                @media(max-width:500px){
                    width: 4em;
                    height: 4em;
                }
                .mask-center{
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    width: 80%;
                    height: 80%;
                    
                }
                .icon-check{
                    mask-image: url('../../Assets/images/iconos/icon-check.svg')
                }
                .icon-chrone{
                    mask-image: url('../../Assets/images/iconos/cronometro.svg')
                }
                .icon-cohete{
                    mask-image: url('../../Assets/images/iconos/cohete.svg')
                }
            }
            .num{
                font-size: 3.5em;
                line-height: 1em;
                transform: translateY(-1px);
                text-align: center;
                @media(max-width:500px){
                    transform: translateY(0);
                }
            }
            .sub-title{
                text-align: center;
                font-size: 1.4em;
                @media(max-width:500px){
                    line-height: 1.2em;
                }
            }
        }
        .stage-step{
            padding: .3rem .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 0 1rem 1rem;
            background-color: #f5f5f5;
            font-size: 1.7em;
            letter-spacing: 1px;
            @media(max-width:500px){
                border-radius: 0 0 3vw 3vw;
                padding: 1.5vw 0;
            }
            .medium{
                transform: translateY(0px);
                @media(max-width:500px){
                    letter-spacing: 1px;
                    transform: translateY(0);
                }
            }
        }
    }
    .stage-5{
        transform: scale(1.2);
        transform-origin: left;
        margin-right: 3rem;
        @media(max-width:960px){
            transform: scale(1);
            margin-right: 0;
        }
    }
}