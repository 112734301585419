.article-editor{
    h2{
        font-size:1.1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    ul{
        padding-left: 1.3rem!important;
        li{
            list-style-type: disc;
            
            ul{
                li{
                    list-style-type: circle;
                }
            }
        }
    }
   
}
#mod-carabayllo{
    .mt-1{
        margin-top: 1rem;
    }
    p{
        margin-bottom: 1rem;
    }   
}
.mod-carabayllo{
   
}
.list-numeric{
    margin-bottom: 1rem!important;
    li{
        list-style-type: decimal;
    }
}
.content-info-all{
    // fon
    padding-top: 14rem;
    padding-bottom: 3rem;
    @media(max-width:740px){
        padding-top: 7rem;
    }
    *{
        font-family: "GalanoGrotesque-Medium";
    }
    h1{
        font-size: 1.5rem;
        margin-bottom: 1rem!important;
    }
    #mod-carabayllo > ul, #mod-lumiere > ul, #mod-carabayllo > ol, #mod-lumiere > ol {
        padding-left: 22px;
    }
    #mod-carabayllo > ul > li, #mod-lumiere > ul > li {
        list-style-type: disc;
    }
    #mod-carabayllo > ul ul, #mod-lumiere > ul ul {
        padding-left: 30px;
        @media(max-width:540px){
            padding-left: 20px;
        }
    }
    #mod-carabayllo > ul ul > li, #mod-lumiere > ul ul > li {
        list-style-type: circle;
    }
    
    a{
        color:var(--third)
    }
    h4{
        margin-top: 1rem;
    }
    #mod-carabayllo .space, #mod-lumiere .space {
        list-style-type: none;
    }
    li,p{
        @media(max-width:740px){
            font-size: .9rem;
        }
    }
    li::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
    }

    .grid{
        @media(max-width:740px){

            display: grid;
        }
    }
    .title{
        font-size: 5vw;
        margin-bottom: 1rem;
        @media(max-width:740px){
            font-size: 1.4rem;
        }
        @media(min-width:700px){
            font-size: 1.5rem;
            display: block;
            text-align: center;
        }
    }
    .link{
        text-decoration: underline;
        color: #007bff;
    }
    .list{
        padding: 0 0 0 1.2rem;
        @media(max-width:740px){
            font-size: 14px;
        }
        li{
            list-style: disc;
        }
    }
    .list-numeric{
        padding: 0 0 0 1.2rem;
        li{
            list-style:decimal;
        }
    }
    .sub-list{
        @media(max-width:780px){
            margin-left: 1rem;
        }
        margin-left: 2rem;
        padding: 0 0 0 1.2rem;
        margin-bottom: 1rem;
        li{
            list-style: disc;
        }
    }
    .link:hover{
        color: #0056b3;
    }
    .sub-title{
        margin-top: 1rem;
        margin-bottom: .5rem;
        @media(max-width:740px){
            font-size: 1.1rem;
        }
        @media(min-width:960px){
            font-size: 1.2rem;
        }
    }
    .paragraph{
        margin-bottom: 3vw;
        text-align: justify;
        font-size: 1rem;
        @media(max-width:700px){
            font-size: 14px;
        }
        @media(min-width:700px){
            font-size: 1rem;
        }
        @media(min-width:1100px){
            margin-bottom: 1rem;
        }
        strong{
            font-size: 1rem;
        }
    }
    .container{
        padding: 0 1.5rem;
        margin: auto;
        @media(min-width:700px){
            max-width: 700px;
        }
        @media(min-width:1100px){
            max-width: 1000px;
        }
    }
}