.Navbar{
    position: fixed;
    top: 0;
    z-index: 100;
    background: white;
    width: 100%;
    height: 7rem;
    padding: 1rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.164);
    padding: 1rem 2rem;
    transition: .3s;
    @media(max-width:740px){
        padding: 1rem 0;
        height: 5rem;
    }
    @media(max-width:540px){
        padding: 1rem 0;
        height: 5rem;
    }
    @media(min-width:1400px){
        height: 9rem;
    }
    .d-flex{
        display: flex;
        align-items: center;
    }
    .button-avance-de-obra{
        width: max-content;
        height: 3rem;
        text-align: left;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        background-color: #FF4E00;
        border-radius: 30rem;
        padding: 0.5rem 1.5rem 0.5rem 0.4rem;
        display: flex;
        align-items: center;
        margin-right: 2rem;
        user-select: none;
        cursor: pointer;
        transition: .5s;
        &.active{
            opacity: 0;
        }
        @media(max-width:500px){
            padding: 0.5rem 1rem 0.5rem 0.2rem;
            width: 5.8rem;
            margin-right: .5rem;
            height: 2.2rem;
        }
        .text{
            line-height: .8rem;
            font-family: "GalanoGrotesque-Medium";
            position: relative;
            top: -0.15rem;
            color: white;
            font-size: .9rem;
            @media(max-width:500px){
                font-size: .6rem;
                line-height: .7rem;
                top: 0;
            }
        }
        .icon{
            border-radius: 40rem;
            background-color: #ed4800;       
            padding: .3rem;
            margin-right: .5rem;
            @media(max-width:500px){     
                margin-right: .3rem;
           }
        }
        .loader {
            height: 1.7rem!important;
            width:1.7rem!important;
            min-width:1.7rem!important;
            background-color: transparent;
            @media(max-width:500px){
                height: 1.2rem!important;
                width:1.2rem!important;
                min-width:1.2rem!important;
            }
            & > div {
                height: 100%!important;
                width: 100%!important;
            }
        }
    }
    .background-1{
        position: absolute;
        transition: .3s;
        z-index: 0;
        height: 100%;
        top: 0;
        @media(max-width:720px){
            // display: none;
            right: -4rem;
        }
        @media(max-width:520px){
            right: -4.6rem;
        }
        @media(min-width:720px){
            right: -8rem;
        }
        @media(min-width:960px){
            right: -10rem;
        }
        @media(min-width:1200px){
            right: -8rem;
        }
        @media(min-width:1400px){
            right: -1rem;
        }
        @media(max-width:1700px){
            right: -17rem;
        }
        @media(max-width:1400px){
            right: -1rem;
        }
    }
    .background-2{
        height: 100%;
        top: 0;
        position: absolute;
        left: -29rem;
        right: 0;
        margin: auto;
        @media(max-width:1700px){
            height: 80%;
        }
        @media(max-width:1400px){
            height: 100%;
        }
    }
    .container-nav{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .first-brand,.last-brand{
            display: block;
            height: 100%;
            width: 8rem;
            @media(max-width:700px){
                width: 6rem;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
        img{
            height: 100%;
        }
        .last-brand{
            height: 60%;
            @media(max-width:700px){
                height: 80%;
            }
        }
    }
    .container-nav{
        @media(min-width:1432px){
            max-width: 1432px;
        }
    }
}
.Navbar.active{
    height: 6rem;
    @media(max-width:740px){
        height: 5rem;
    }
    .background-1{
        right: -1rem;
    }
}