dialog{
    height: 100vh;
    width: 101%;
    display: block;
    position: fixed;
    margin: 0;
    top: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.466);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    .modal{
        width: 30vw;
        height: 18vw;
        position: relative;
        background: white;
        border: 2px solid black;
        border-radius: 1vw;
    }
}
dialog[open]{
    display: flex!important;
}
dialog:not([open]){
    animation: 0s opacidad forwards;
}
dialog::backdrop {
    background: red;
}
@keyframes opacidad{
    0%{opacity: 1;}
    99%{
        opacity: 0;
    }
    100%{
        opacity: 0;
        z-index: -1;
    }
}
.content-Modal-error{
    display: none;
}
.content-Modal-error.active{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000085;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}