:root{
    --mainColor:#FFB113;
    --altura:100vh;
}
html , body{
    margin: 0;
    padding: 0;
}
*{
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    font-family: "MyriadPro-Regular";
    -webkit-tap-highlight-color:transparent;
}
* a {
    text-decoration: none;
}

button:focus{
    outline: none;
}
.d-block{
    display: block;
}
.no-wrap{
    display: block;
    @media (min-width:800px){
        display: inline;
    }
}
.mainColor{
    color: #FFB113;
}