.btn-signal-right{
    mask-image: url("../../Assets/images/iconos/icon-signal-right.svg");
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
}
.calcula-cuotas{
    .title{
        .title-left{
            @media(max-width:500px){
                top: 9vw!important;
            }
        }
    }
}
.calculator{
    padding-top:4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 7vw;
    height: 56rem;
    // @media(max-width:1260px){
    //     margin-bottom: 19vw;
    // }
    
    @media(max-width:1260px){
        margin-bottom:11rem;
    }
    @media(max-width:900px){
        height: auto;
        margin-bottom: 0;
        padding-bottom: 50vw;
    }
    @media(max-width:700px){
        margin-bottom: 19vw;
    }
    @media(max-width:500px){
        padding-top: 2vw;
    }
    
    .steps{
        width: 38rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 3rem;
        @media(max-width:740px){
            width:100%;
        }
        @media(max-width:600px){
            width:80%;
        }
        @media(max-width:500px){
            width:92%;
            margin-bottom: 12vw;
        }
        .line-step{
            width: 100%;
            height: 1px;
            background: var(--greenLigth);
            position: absolute;
            bottom: 0;
            top: 0;
            margin: auto;
            left: -.1vw;
        }
        .step{
            position: relative;
            width: 4rem;
            height: 4rem;
            border-radius: 100%;
            margin: 0 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 2rem;
            transition: 1s;
            *{
                font-family: "GalanoGrotesque-Bold";
            }
            @media(max-width:500px){
                width: 15vw;
                height: 15vw;
            }
            @media(max-width:500px){
                width: 3.5rem;
                min-width: 3.5rem;
                height: 3.5rem;
            }
            .text-step{
                width: 100%;
                height: 100%;
                // padding-bottom: .7rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--greenLigth);
                border-radius: 100%;
                cursor: pointer;
                @media(max-width:500px){
                    text-align: center;
                    padding-bottom: 0rem;
                }
            }
            .img-step{
                position: absolute;
                width: 100%;
                height: 100%;
                transform: scale(.8);
                z-index: -1;
                cursor: pointer;
            }
        }
        .step.active{
            transform: scale(1.2);
            .img-step{
                animation: .4s imgStep .2s forwards linear;
            }
            @keyframes imgStep {
                0%{
                    transform: scale(.8);
                    z-index: -1;
                }
                80%{
                    z-index: 2;
                }
                100%{
                    transform: scale(1.6);
                    z-index: 2;
                }
            }
        }
    }
    form.form-step{
        background: white;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        border-radius: 1rem;
        box-shadow: 0px 0px 10px 0px #0000002e;
        @media(max-width:500px){
            box-shadow: initial;
            padding-top: 0;
            background: transparent;
        }
    }
    .content-forms{
        position: relative;
        .title-form{
            font-size: 1.2rem;
            border-radius: .4rem;
            background-color: #35a9df;
            color:white;
            font-family: "GalanoGrotesque-Medium";
            white-space: nowrap;
            padding: .4rem 1rem;
            padding-bottom: .8rem;
            margin-bottom: 3rem;
            height: 3.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width:600px){
                margin-bottom: 11vw;
                font-size: 4.5vw;
                padding-top: .8rem;
                padding-bottom: .8rem;
                padding-left: .7rem;
                padding-right: .7rem;
            }
        }
    }
    
    .form-step{
        width: 24rem;
        position: absolute;
        top: 0;
        visibility: hidden;
        display: none;
        @media(max-width:740px){
            width: 26rem;
        }
        @media(max-width:500px){
            width: 89vw;
        }
        .content-input{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
        }
        .row-step{
            display: flex;
            position: relative;
            @media(max-width:740px){
                flex-wrap: wrap;
            }
            .flex-column{
                position: relative;
                @media(max-width:740px){
                    width: 100%;
                }
            }
            
            .advice{
                mask-image: url("../../Assets/images/iconos/exclamacion.svg");
                mask-position: center;
                mask-size: contain;
                mask-repeat: no-repeat;
                position: absolute;
                right: .5rem;
                height: 1.6rem;
                width: 1.6rem;
                top: 0;
                bottom: 0;
                margin:auto;
                padding-right: .1rem;
                background-color: red;
            }
            
        }
        .text-step{
            font-family: "GalanoGrotesque-Medium";
            color: var(--Primary);
            font-size: 1.3rem;
            padding-bottom: .5rem;
            @media(max-width:900px){
                text-align: center;
            }
            @media(max-width:500px){
                font-size: 4.5vw;
                line-height: 6vw;
                text-align: center;
            }
        }
        input,select{
            min-width: 20rem;
            margin-bottom: 0;
            height: 3rem;
            border: 1px solid var(--Primary);
            border-radius: 100rem;
            padding: .7rem;
            padding-left: 1.3rem;
            // padding-top: .3rem;
            padding-bottom: .8rem;
            font-size: 1rem;
            margin-bottom: 1.1rem;
            font-family: "GalanoGrotesque-Medium";
            color: var(--gray);
            &.invalid {
                border-color: red;
            }
            @media(max-width:900px){
                width: 100%;
            }
            @media(max-width:500px){
                padding-top: .8rem;
                font-size: 4.5vw;
                height: 13vw;
                min-width: 100%;
            }
        }

        .error-msg{
            position: absolute;
            bottom: -29px;
            right: 0;
            font-family: "GalanoGrotesque-Medium";
            color: red;
        }
        option{
            font-family: "GalanoGrotesque-Medium";
        }
        select{
            background-image: url("../../Assets/images/iconos/btn-down.svg");
            background-position: calc(100% - 12px) calc(1em + 1px), calc(100% - 15px) calc(1em + 2px), calc(100% - .5em) 0.5em;
            background-size: 12px 12px, 5px 5px, 1.5em 1.5em;
            background-repeat: no-repeat;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-color: white;
        }
        select:focus{
            background-position: calc(100% - 12px) calc(1em + 1px), calc(100% - 15px) calc(1em + 2px), calc(100% - .5em) 0.5em;
        }
        .btn-submit{
            width: 13rem;
            padding: 1rem;
            // padding-top: .4rem;
            border-radius: 100rem;
            border: 0;
            margin: auto;
            background: #35a9df;
            font-size: 1.2rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-top: 2rem;
            cursor: pointer;
            .text-submit{
                color: white;
                font-family: "GalanoGrotesque-Medium";
            }
            .btn-signal-right{
                width: .9rem;
                height: .9rem;
                background: white;
                margin-bottom: .28rem;
                margin-left: .5rem;
                @media(max-width:500px){
                    margin-bottom: 0rem;
                }
            }
            @media(max-width:500px){
                margin-top: 3vw;
                padding-top: .8rem;
                padding-bottom: .8rem;
                align-items: center;
            }
        }
    }
    form.form-step-2,.calculate{
        padding-left: 1rem;
        padding-right: 1rem;
        background: white;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        border-radius: 1rem;
        box-shadow: 0px 0px 10px 0px #00000014;
        @media(max-width:500px){
            box-shadow: initial;
            background: transparent;
            padding-top: 0rem;
        }
    }
    .calculate{
        padding-left: 2rem;
        padding-right: 2rem;
        .text-step{
            padding-bottom: 0;
        }
    }
    .form-step-2{
        width: 46rem;
        @media(max-width:740px){
            width: 88%;
            margin: auto;
        }
        @media(max-width:500px){
            width: 100%;
        }
        .content-title-form{
            margin-top: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .row-step{
            margin-bottom: 1.7rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 1rem;
            padding-right: 1rem;
            @media(max-width:740px){
                padding-left: 0rem;
                padding-right: 0rem;
                justify-content: center;
            }
            .text-pay{
                color: var(--third);
                font-family: "GalanoGrotesque-Bold";
                font-size: 2.5rem;
                display: flex;
                align-items: center;
            }
            .c-black{
                color: black;
            }
            .exponente{
                font-family: "GalanoGrotesque-Bold";
                font-size: 1rem;
                margin-left: .2rem;
                padding-bottom: .5rem;
            }
            .message{
                font-family: "GalanoGrotesque-Medium";
                font-size: .65rem;
                margin-top: .5rem;
                color: red;
            }
            .content-input{
                position: relative;
            }
            .icon-error{
                mask-image: url("../../Assets/images/iconos/exclamacion.svg");
                mask-size: contain;
                mask-repeat: no-repeat;
                position: absolute;
                top: .7rem;
                right: .7rem;
                width: 1.4rem;
                height: 1.4rem;
                background: red;
            }
        }
        // .row-step.line{
        //     border: 2px solid var(--third);
        //     border-radius: .8rem;
        //     padding-bottom: 1rem;
        // }
        .paragraph{
            font-family: "GalanoGrotesque-Light";
            font-size: .8rem;
            line-height: .9rem;
            text-align: left;
            width: 82%;
            margin-right: auto;
            margin-left: auto;
            @media(max-width:500px){
                font-size: 3vw;
            }
        }
        .paragraph-2{
            margin-bottom: 4rem;
            @media(max-width:500px){
                margin-bottom: 10vw;
            }
        }
        input,select{
            min-width: 20rem;
            margin-bottom: 0;
            height: 3rem;
            @media(max-width:500px){
                height: 13vw;
                min-width: 100%;
            }
        }
        .title{
            font-size: 3.5rem;
            font-family: "GalanoGrotesque-Bold";
            color: var(--Primary);
            align-self: center;
            @media(max-width:500px){
                font-size: 9vw; 
                margin-bottom: 0vw;
            }
            .title-left,.title-right{
                width: 4vw;
                bottom: 0;
                top: 36%;
                transform: scale(1);
                opacity: 1!important;
                @media(max-width:700px){
                    width: 13vw;
                    transform: scale(1)!important;
                }
            }
            .background{
                position: absolute;
                width: 100%;
                height: 100%;
                transform: scale(1.1);
            };
            .title-left{
                left: -5vw;
                @media(max-width:700px){
                    left: -15vw;
                    top: 4vw!important;
                }
            }
            .title-right{
                right: -5vw;
                @media(max-width:700px){
                    right: -15vw;
                    top: 4vw;
                }
            }
        }
        .sub-title{
            text-align: center;
            font-family: "GalanoGrotesque-Medium";
            // font-family: "GalanoGrotesque-Bold";
            color: var(--Primary);
            font-size: 1.3rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            @media(max-width:500px){
                margin-top: 1rem;
                font-size: 4.5vw; 
            }
        }
        .btn-calculate{
            font-size: 1.4rem;
            border: 0;
            width: 19rem;
            padding: 1.3rem 2rem;
            padding-top: .8rem;
            border-radius: 100rem;
            color: white;
            font-family: "GalanoGrotesque-Medium";
            cursor: pointer;
            @media(max-width:500px){
                font-size: 5vw;
                padding: 3vw 2vw;
                width: 70vw;
                margin-bottom: 6vw;
                text-align: center;
            }
        }
    }
    .form-step.active{
        visibility:visible;
        position: relative;
        display: flex;
    }
    .form-step-1{
        padding-right: 2rem;
        padding-left: 2rem;
        @media(max-width:500px){
            padding-right: 0;
            padding-left: 0;
            .content-input{
                margin-top: 0!important;
                margin-bottom: 2vw!important;
            }
            .text-term{
                font-size: 4.5vw!important;
                padding-bottom: 0!important;
            }
        }
        input{
            width: 100%;
        }
    }
    .form-step.active.form-step-1{
        align-items: center;
        justify-content: center;
        .content-input{
            display: flex;
            align-items: center;
        }
        #terms-step{
            display: flex;
            position: absolute;
            visibility: hidden;
            z-index: -1;
        }
        .button-term{
            height: .9rem;
            width: .9rem;
            border: 1px solid var(--Primary);
            border-radius: .3rem;
            margin-right: .6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .text-term{
            font-size: .8rem;
            color: var(--dark);
            display: block;
            // padding-bottom: .3rem;    
            font-family: "GalanoGrotesque-Medium";
            *{
                font-family: "GalanoGrotesque-Medium";
            }
            strong,a{
                color: var(--dark);
            }
        }
        #terms-step:checked ~ .button-term{
            background-color: var(--Primary);
            .checked{
                mask-image: url("../../Assets/images/iconos/checked.svg");
                mask-size: 80% 80%;
                mask-position: center;
                mask-repeat: no-repeat;
                background: white;
                width: 80%;
                height: 80%;
            }
        } 
    }
}