.loader-page {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	pointer-events: none;
	position: fixed;
	top: 0;
	& > div {
		width: 150px !important;
		height: 150px !important;
	}
	&.hidden {
		animation: fadeOut 0.5s forwards;
	}
	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}

.loader-global {
	z-index: 2;
}
